import { Box } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { BaseCard } from "../components/common/BaseCard";
import { Pagenation } from "../components/common/Pagenation";
import { ScrollToTop } from "../components/common/ScrollToTop";
import { AnalysisCreateIcon } from "../components/home/AnalysisCreateIcon";
import { HomeListPerformance } from "../components/home/HomeListPerformance";
import { ReloadAnnounce } from "../components/home/ReloadAnnounce";
import { SearchArea } from "../components/home/SearchArea";
import { LoadingContext, UserContext } from "../Main";
import { historyDataType } from "../types/type";

export const Home = () => {
  const user = useContext(UserContext);
  const accessToken = user?.token;
  if (accessToken === "") {
    console.log("home Row: accessToken is null.");
  }
  let axiosInstance = axios.create({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const [allPageNum, setAllPageNum] = useState<number>(1);
  const [pageNum, setPageNum] = useState<number>(1);
  const [dataList, setDataList] = useState<historyDataType[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const { setIsLoading } = useContext(LoadingContext);
  const [rowNum, setRowNum] = useState<Number>(10);
  const [loadCompleted, setLoadCompleted] = useState<boolean>(false);

  const getPageNum = async () => {
    if (searchKeyword === "") {
      const tmpPageNum = await axiosInstance.get(
        `${process.env.REACT_APP_PAGES_API}&count=${rowNum}&username=${user?.email}`
      );
      setAllPageNum(tmpPageNum.data.pages);
    } else {
      const tmpPageNum = await axiosInstance.get(
        `${process.env.REACT_APP_PAGES_API}&count=${rowNum}&username=${user?.email}&site_name=${searchKeyword}`
      );
      setAllPageNum(tmpPageNum.data.pages);
    }
  };

  const getDataList = async () => {
    setIsLoading(true);
    if (searchKeyword === "") {
      const tmpData = await axiosInstance.get(
        `${process.env.REACT_APP_ITEMS_BY_PAGE_API}&current=${pageNum}&count=${rowNum}&username=${user?.email}`
      );
      setDataList(tmpData?.data);

      setIsLoading(false);
    } else {
      const tmpData = await axiosInstance.get(
        `${process.env.REACT_APP_ITEMS_BY_PAGE_API}&current=${pageNum}&count=${rowNum}&username=${user?.email}&site_name=${searchKeyword}`
      );
      setDataList(tmpData?.data);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (accessToken) {
      getPageNum();
      setPageNum(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword, rowNum]);

  useEffect(() => {
    if (accessToken) {
      getDataList();
      setLoadCompleted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum, searchKeyword, rowNum]);

  useEffect(() => {
    if (accessToken && !loadCompleted) {
      getPageNum();
      setPageNum(1);
      getDataList();
      setLoadCompleted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const updateAnalysisData = (page: number) => {
    setPageNum(page);
  };

  return (
    <Box>
      <SearchArea
        setSearchKeyword={setSearchKeyword}
        rowNum={rowNum}
        setRowNum={setRowNum}
      />
      <ScrollToTop pageNum={pageNum} />
      <BaseCard>
        <Box>
          <HomeListPerformance items={dataList} getDataList={getDataList} />
          <Pagenation
            allPage={allPageNum}
            pageNum={pageNum}
            updateAnalysisData={updateAnalysisData}
          />
        </Box>
      </BaseCard>
      <AnalysisCreateIcon />
      <ReloadAnnounce />
    </Box>
  );
};
