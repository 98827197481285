import { styled, Typography } from "@mui/material";
import { theme } from "../../../libs/theme";

export const SmallTitle = styled(Typography)(() => ({
  color: theme.gray800,
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  Fontstyle: "Regular",
  fontSize: "20px",
}));

export const TitleTypo = styled(Typography)(() => ({
  color: theme.gray800,
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  Fontstyle: "Regular",
  fontSize: "24px",
  paddingBottom: "56px",
}));

export const TightTitleTypo = styled(Typography)(() => ({
  color: theme.gray800,
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  Fontstyle: "Regular",
  fontSize: "24px",
  paddingBottom: "0px",
}));
