import { Box } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BaseCard } from "../components/common/BaseCard";
import { DropArea } from "../components/common/DropArea";
import { DropBox } from "../components/common/DropBox";
import { CancelButton, SendButton } from "../components/common/styles/button";
import { TitleTypo } from "../components/common/styles/title";
import { NotOpenDetailModal } from "../components/home/NotOpenDetailModal";
import { RegisterCreatePerformance } from "../components/registerCreate/RegisterCreatePerformance";
import { textJa } from "../locales/textJa";

export const RegisterCreate = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [notDetailModalOpen, setNotDetailModalOpen] = useState<boolean>(false);
  const [form, setForm] = useState<string>("");
  const [siteForm, setSiteForm] = useState<string>("");
  const handleOpen = () => {
    if (
      form !== "" &&
      siteForm !== "" &&
      files !== null &&
      files.length !== 0
    ) {
      setOpen(true);
    } else {
      setNotDetailModalOpen(true);
    }
  };
  const handleClose = () => setOpen(false);
  const [files, setFiles] = useState<File[] | FileList | null>(null);
  const [isDragOver, setIsDragOver] = useState<boolean>(false);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
          mb: "8px",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <TitleTypo>{textJa.registerCreate.title}</TitleTypo>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <CancelButton onClick={() => navigate("/register")}>
            {textJa.common.cancelButton}
          </CancelButton>
          <SendButton onClick={handleOpen}>
            {textJa.registerCreate.registerButton}
          </SendButton>
        </Box>
      </Box>
      <DropArea setIsDragOver={setIsDragOver} setFiles={setFiles}>
        <DropBox isDragOver={isDragOver} />
      </DropArea>
      <BaseCard>
        <RegisterCreatePerformance
          open={open}
          handleClose={handleClose}
          files={files}
          setFiles={setFiles}
          form={form}
          setForm={setForm}
          siteForm={siteForm}
          setSiteForm={setSiteForm}
        />
      </BaseCard>
      <NotOpenDetailModal
        notDetailModalOpen={notDetailModalOpen}
        setNotDetailModalOpen={setNotDetailModalOpen}
        word={textJa.registerCreate.notOpen}
      />
    </Box>
  );
};
