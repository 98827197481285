import { Box } from "@mui/material";
import "../App.css";
import { LoginHeader } from "../components/login/LoginHeader";

export const Login = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        height: "100%",
        width: "100%",
        minHeight: "720px",
        minWidth: "920px",
        position: "absolute",
        overflow: "hidden", //これがないとheight100%指定なのに微妙に余白がでてしまう
      }}
    >
      <Box className="login-page-left" width="50%" height="100%"></Box>
      <Box
        width="50%"
        height="100%"
        sx={{
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
        }}
      >
        <LoginHeader />
      </Box>
    </Box>
  );
};
