import { errorMessage } from "../utils/errorMessage";
import { extension } from "../utils/extension";

class AudioRecorder {
  audioRecorder: MediaRecorder | null;
  audioChunks: Blob[];
  stream: MediaStream | null;
  audioContext: AudioContext | null;
  sourceNode: MediaStreamAudioSourceNode | null;
  analyserNode: AnalyserNode | null;
  voiceClipLevel: any;
  voiceAveraging: any;
  voiceClipLag: any;
  voiceLastClip: any;
  voiceClipping: boolean;
  volume: number;
  meter: any;
  processor: any;
  isChecked: boolean;
  constructor() {
    this.audioRecorder = null;
    this.audioChunks = [];
    this.stream = null;
    this.audioContext = null;
    this.sourceNode = null;
    this.analyserNode = null;
    this.voiceClipLevel = null;
    this.voiceAveraging = null;
    this.voiceClipLag = null;
    this.voiceLastClip = null;
    this.voiceClipping = false;
    this.volume = 0;
    this.meter = null;
    this.processor = null;
    this.isChecked = false;
  }

  initialize = async () => {
    try {
      if (!this.isChecked) {
        await this.isSupported();
        this.isChecked = true;
      }
      this.stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      // initialize audioChunks
      // もしポーリング中も連続のデータが欲しい場合はthis.audioChunks = [];の部分を消せばOK
      // 今回は一回送信したら不要なので毎回初期化する
      this.audioChunks = [];
      this.audioRecorder = new MediaRecorder(this.stream);
      this.audioContext = new AudioContext();
      this.audioRecorder.addEventListener(
        "dataavailable",
        (event: { data: Blob }) => {
          this.audioChunks.push(event.data);
        }
      );
      // リアルタイムで音声取得部分
      this.sourceNode = this.audioContext.createMediaStreamSource(this.stream);
      this.meter = this.createAudioMeter(this.audioContext);
      this.sourceNode.connect(this.meter);
    } catch (err) {
      console.error(errorMessage(err));
    }
  };

  finishTrack = () => {
    if (this.stream) {
      this.stream.getTracks().forEach(function (track: MediaStreamTrack) {
        track.stop();
      });
    }
  };

  start = async () => {
    try {
      await this.initialize();
      if (this.audioRecorder) this.audioRecorder.start();
    } catch (err) {
      console.error(errorMessage(err));
    }
  };

  stop = async () => {
    try {
      if (this.audioRecorder && this.audioRecorder.state !== "inactive") {
        this.audioRecorder.stop();
      }
      // processorの初期化
      this.processor.disconnect();
      this.processor.onaudioprocess = null;
      this.finishTrack();
      return await this.stopStream();

      // もしblobURLが欲しい場合は以下
      // return URL.createObjectURL(blob as Blob | MediaSource);
    } catch (err) {
      console.error(errorMessage(err));
    }
  };

  stopStream = (): Promise<{ blob: Blob; extension: string }> => {
    return new Promise((resolve) => {
      if (!this.audioRecorder) return;
      this.audioRecorder.addEventListener("stop", async () => {
        if (!this.audioRecorder || !this.stream) return;
        const audioBlob = new Blob(this.audioChunks, {
          type: this.audioRecorder.mimeType,
        });
        resolve({
          blob: audioBlob,
          extension: extension(this.audioRecorder.mimeType),
        });
      });
    });
  };

  isSupported = () => {
    return new Promise((resolve, reject) => {
      if (navigator.mediaDevices) {
        resolve(true);
      }
      reject(new Error("getUserMedia not supported on this browser!"));
    });
  };

  getVolumes = () => {
    return this.volume * 100;
  };

  // メーターの生成
  createAudioMeter = (audioContext: AudioContext) => {
    // メーターの生成
    this.processor = audioContext.createScriptProcessor(512) as any;
    this.processor.onaudioprocess = this.volumeAudioProcess;
    this.processor.clipping = false;
    this.processor.lastClip = 0;
    this.processor.volume = 0;
    this.processor.clipLevel = 0.98;
    this.processor.averaging = 0.95;
    this.processor.clipLag = 750;
    this.processor.connect(audioContext.destination);

    // クリップチェック時に呼ばれる
    this.processor.checkClipping = function () {
      this.voiceClipLevel = this.clipLevel;
      this.voiceAveraging = this.averaging;
      this.voiceClipLag = this.clipLag;
      this.voiceLastClip = this.lastClip;
      this.voiceClipping = this.clipping;
      if (!this.clipping) {
        return false;
      }
      if (this.lastClip + this.clipLag < window.performance.now()) {
        this.clipping = false;
      }
      return this.clipping;
    };

    // シャットダウン時に呼ばれる
    this.processor.shutdown = function () {
      this.disconnect();
      this.onaudioprocess = null;
    };

    return this.processor;
  };
  // オーディオ処理時に呼ばれる
  volumeAudioProcess = (event: any) => {
    const buf = event.inputBuffer.getChannelData(0);
    const bufLength = buf.length;
    let sum = 0;
    let x;

    // 平均ボリュームの計算
    for (var i = 0; i < bufLength; i++) {
      x = buf[i];
      if (Math.abs(x) >= this.voiceClipLevel) {
        this.voiceClipping = true;
        this.voiceLastClip = window.performance.now();
      }
      sum += x * x;
    }
    const rms = Math.sqrt(sum / bufLength);
    this.volume = Math.max(rms, this.volume * this.voiceAveraging);
  };
}

export default AudioRecorder;
