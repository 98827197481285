import { styled, TableCell, Typography } from "@mui/material";
import { theme } from "../../../libs/theme";

export const StyledHeader = styled(Typography)(() => ({
  color: theme.gray800,
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "22px",
}));

export const StyledCell = styled(TableCell)(() => ({
  borderBottom: `2px solid ${theme.gray300}`,
}));
