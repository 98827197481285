import { useMsal } from "@azure/msal-react";
import { Box, Button, Menu, Typography } from "@mui/material";

import FeatherIcon from "feather-icons-react";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";
import { UserContext } from "../../Main";
import { LogoutButton } from "../common/styles/button";

export const ProfileDD = () => {
  const { instance } = useMsal();
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const user = useContext(UserContext);

  const logout = (logoutType: string) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };

  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClick = (link: string) => {
    setAnchorEl4(null);
    navigate(link);
  };

  const handleClick4 = (event: any) => {
    setAnchorEl4(event.currentTarget);
  };

  const handleClose4 = () => {
    setAnchorEl4(null);
  };

  const getUserName = () => {
    if (!!user.familyName && !!user.givenName) {
      return user.familyName + " " + user.givenName;
    } else {
      return user.email;
    }
  };

  return (
    <>
      <Button
        aria-label="menu"
        color="inherit"
        aria-controls="profile-menu"
        aria-haspopup="true"
        onClick={handleClick4}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              background: theme.gradOrange,
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              color: theme.white,
            }}
          >
            <FeatherIcon icon="user" width="70%" height="70%" />
          </Box>
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "flex",
              },
              alignItems: "center",
              color: theme.gray800,
            }}
          >
            <Typography
              sx={{
                fontWeight: 400,
                ml: 1,
                mr: 2,
                textTransform: "none",
              }}
            >
              {getUserName()}
            </Typography>
            <FeatherIcon icon="chevron-down" width="20" height="20" />
          </Box>
        </Box>
      </Button>

      <Menu
        id="profile-menu"
        anchorEl={anchorEl4}
        keepMounted
        open={Boolean(anchorEl4)}
        onClose={handleClose4}
        sx={{
          "& .MuiMenu-paper": {
            boxShadow: `0px 7px 30px ${theme.cardShadow}`,
            width: "432px",
            borderRadius: "24px",
          },
        }}
      >
        <Box
          sx={{
            my: 1,
            mx: 2,
          }}
        >
          <Box p={2} pt={0}>
            <Box display="flex" alignItems="center">
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "1.125rem",
                  lineHeight: "1.5",
                  color: theme.gray800,
                }}
              >
                {textJa.header.profileDD.title}
              </Typography>
            </Box>
            <Box
              sx={{
                pb: 2,
                pt: 1,
              }}
            >
              <Box display="flex" alignItems="center">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    background: theme.gradOrange,
                    width: "81px",
                    height: "81px",
                    borderRadius: "50%",
                    color: theme.white,
                  }}
                >
                  <FeatherIcon icon="user" width="70%" height="70%" />
                </Box>
                <Box
                  sx={{
                    ml: 2,
                  }}
                >
                  <Typography
                    sx={{
                      lineHeight: "1.235",
                      fontWeight: 700,
                      fontSize: "1.125rem",
                      color: theme.gray800,
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      mt: 2,
                    }}
                  >
                    {getUserName()}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Typography
                      display="flex"
                      alignItems="center"
                      sx={{
                        mr: 1,
                        color: theme.gray500,
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        mt: 0.7,
                      }}
                    >
                      <FeatherIcon icon="mail" width="22" height="22" />
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "0.875rem",
                        lineHeight: "1.5",
                        color: theme.gray500,
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        mt: 1,
                      }}
                    >
                      {user.email}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* {menuItems.map((item, index) => (
              <Box key={index}>
                <Divider />
                <MenuItem
                  sx={{
                    pt: 2,
                    pb: 2,
                  }}
                  onClick={() => handleClick(item.href)}
                >
                  <Box display="flex" alignItems="center">
                    <Button
                      sx={{
                        backgroundColor: item.backgroundColor,
                        color: item.color,
                        boxShadow: "none",
                        minWidth: "50px",
                        width: "50px",
                        height: "40px",
                        borderRadius: "10px",
                      }}
                    >
                      <FeatherIcon icon={item.icon} width="24" height="24" />
                    </Button>
                    <Box
                      sx={{
                        ml: 3,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1rem",
                          lineHeight: "1.235",
                          color: theme.gray800,
                          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "0.875rem",
                          lineHeight: "1.5",
                          color: theme.gray500,
                          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        }}
                      >
                        {item.category}
                      </Typography>
                    </Box>
                  </Box>
                </MenuItem>
              </Box>
            ))} */}
          </Box>
          <Box px={2} pb={2}>
            <LogoutButton
              onClick={() => logout("redirect")}
              fullWidth
              variant="contained"
            >
              {textJa.LOGOUT}
            </LogoutButton>
          </Box>
        </Box>
      </Menu>
    </>
  );
};
