import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";

type Props = {
  size: string;
  messagetype: string;
};

export const StatusTag: React.FC<Props> = ({ size, messagetype }) => {
  const [message, setMessage] = useState<string | null>(null);
  const [tagColor, setTagColor] = useState<string>(theme.gray500);

  useEffect(() => {
    if (messagetype.indexOf(textJa.analysisStatus.succeeded) !== -1) {
      setTagColor(theme.defaultColor);
    } else if (messagetype.indexOf(textJa.analysisStatus.failed) !== -1) {
      setTagColor(theme.winered);
    } else if (messagetype.indexOf(textJa.analysisStatus.conversion) !== -1) {
      setTagColor(theme.gray500);
    } else if (
      messagetype.indexOf(textJa.analysisStatus.transcription) !== -1
    ) {
      setTagColor(theme.gray750);
    } else if (messagetype.indexOf(textJa.analysisStatus.speaker) !== -1) {
      setTagColor(theme.gray800);
    } else if (messagetype.indexOf(textJa.analysisStatus.send) !== -1) {
      setTagColor(theme.purple.light);
    } else {
      setTagColor(theme.lightorange.main);
    }
    setMessage(messagetype);
  }, [messagetype]);
  return (
    <>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "500",
          color: theme.white,
          backgroundColor: tagColor,
          borderRadius: 50,
          padding: `${size} 24px`,
          display: "inline-block",
        }}
      >
        {message}
      </Typography>
    </>
  );
};
