import { Box } from "@mui/material";
import { textJa } from "../../locales/textJa";

type Props = {
  children: React.ReactElement;
  setIsDragOver: React.Dispatch<React.SetStateAction<boolean>>;
  setFiles: React.Dispatch<React.SetStateAction<FileList | File[] | null>>;
};

export const DropArea: React.FC<Props> = ({
  children,
  setIsDragOver,
  setFiles,
}) => {
  const handleDragOver = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragOver(false);
    const item = e.dataTransfer.items[0];
    const entry = item.webkitGetAsEntry();

    if (entry.isFile) {
      const file: File = await new Promise((resolve) => {
        entry.file((file: any) => {
          resolve(file);
        });
      });

      setFiles([file]);
    } else if (entry.isDirectory) {
      alert(textJa.common.alertDir);
    }
  };

  return (
    <Box
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragLeave={handleDragLeave}
      sx={{ width: "100%", mb: "24px" }}
    >
      {children}
    </Box>
  );
};
