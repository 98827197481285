import { Badge, Box, IconButton, Typography } from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { useEffect, useRef, useState } from "react";
import { formatNumberTime } from "../../libs/formatTime";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";
import { analysisResultType } from "../../types/type";
import {
  StyledMultiTextField,
  StyledTextField,
} from "../common/styles/textField";
import "./style.css";

type Props = {
  item: analysisResultType;
  audioItem: any;
  index: number;
  analysisData: analysisResultType[];
  setAnalysisData: React.Dispatch<React.SetStateAction<analysisResultType[]>>;
  nextPlayback: number | null;
  setNextPlayback: React.Dispatch<React.SetStateAction<number | null>>;
  playbackRate: number;
  setPlaybackRate: React.Dispatch<React.SetStateAction<number>>;
  playbackRateSwitch: number;
  setPlaybackRateSwitch: React.Dispatch<React.SetStateAction<number>>;
  audioStartSwitch: boolean;
  audioPauseSwitch: boolean;
  audioForwardSwitch: boolean;
  audioBackwardSwitch: boolean;
  tenForwardSwitch: boolean;
  tenBackwardSwitch: boolean;
  thirtyForwardSwitch: boolean;
  thirtyBackwardSwitch: boolean;
  audioValue: number;
  removeEventListener: () => void;
  addEventListener: () => void;
  title: any;
};

export const Row: React.FC<Props> = ({
  item,
  audioItem,
  index,
  analysisData,
  setAnalysisData,
  nextPlayback,
  setNextPlayback,
  playbackRate,
  setPlaybackRate,
  playbackRateSwitch,
  setPlaybackRateSwitch,
  audioStartSwitch,
  audioPauseSwitch,
  audioForwardSwitch,
  audioBackwardSwitch,
  tenForwardSwitch,
  tenBackwardSwitch,
  thirtyForwardSwitch,
  thirtyBackwardSwitch,
  audioValue,
  removeEventListener,
  addEventListener,
  title,
}) => {
  const audioEl = useRef<HTMLAudioElement>(null);

  const [editForm, setEditForm] = useState<analysisResultType>(item);
  const [startTime, setStartTime] = useState<number>();
  const [endTime, setEndTime] = useState<number>();
  useEffect(() => {
    setEditForm(item);
    setStartTime(item.startTime);
    setEndTime(item.endTime);

    // audioElのcurrentTimeリセット
    if (audioEl !== null && audioEl.current !== null) {
      audioEl.current.currentTime = 0;
    }
  }, [item]);

  useEffect(() => {
    const tmpData = analysisData;
    tmpData[index] = editForm;
    setAnalysisData(tmpData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editForm]);

  const handleTimeUpdate = (e: any) => {
    const time = e.target.currentTime;
    if (
      endTime !== undefined &&
      startTime !== undefined &&
      time - endTime > 0.05
    ) {
      e.target.pause();
      e.target.currentTime = startTime;
      setNextPlayback(index + 1);
    } else if (startTime !== undefined && startTime - time > 0.05) {
      e.target.currentTime = startTime;
    }
  };

  const handlePlaybackRate = (e: any) => {
    setPlaybackRate(() => e.target.playbackRate);
    if (playbackRate !== playbackRateSwitch) {
      removeEventListener();
      setPlaybackRateSwitch(e.target.playbackRate);
      addEventListener();
    }
  };

  useEffect(() => {
    if (audioEl !== null && audioEl.current !== null) {
      audioEl.current.addEventListener("ended", () => {
        setNextPlayback(index + 1);
      });
    }
    return () => {
      if (audioEl !== null && audioEl.current !== null) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        audioEl.current.removeEventListener("ended", () => {
          setNextPlayback(index + 1);
        });
      }
    };
  }, [index, setNextPlayback]);

  useEffect(() => {
    if (
      index === nextPlayback &&
      audioEl !== null &&
      audioEl.current !== null
    ) {
      // 解析履歴画面の、連続再生時のスタート位置のリセット。
      const currentTime = audioEl.current.currentTime;
      if (
        startTime !== undefined &&
        startTime < currentTime &&
        currentTime !== 0
      ) {
        audioEl.current.currentTime = startTime;
      }
      audioEl.current.play();
    } else if (
      index !== nextPlayback &&
      audioEl !== null &&
      audioEl.current !== null
    ) {
      audioEl.current.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, nextPlayback, audioStartSwitch]);

  useEffect(() => {
    if (audioEl !== null && audioEl.current !== null) {
      audioEl.current.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioPauseSwitch]);

  useEffect(() => {
    if (
      index === nextPlayback &&
      audioEl !== null &&
      audioEl.current !== null
    ) {
      audioEl.current.currentTime += 3;
    } else if (
      index !== nextPlayback &&
      audioEl !== null &&
      audioEl.current !== null
    ) {
      audioEl.current.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioForwardSwitch]);

  useEffect(() => {
    if (
      index === nextPlayback &&
      audioEl !== null &&
      audioEl.current !== null
    ) {
      audioEl.current.currentTime += 10;
    } else if (
      index !== nextPlayback &&
      audioEl !== null &&
      audioEl.current !== null
    ) {
      audioEl.current.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenForwardSwitch]);

  useEffect(() => {
    if (
      index === nextPlayback &&
      audioEl !== null &&
      audioEl.current !== null
    ) {
      audioEl.current.currentTime += 30;
    } else if (
      index !== nextPlayback &&
      audioEl !== null &&
      audioEl.current !== null
    ) {
      audioEl.current.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thirtyForwardSwitch]);

  useEffect(() => {
    if (
      index === nextPlayback &&
      audioEl !== null &&
      audioEl.current !== null
    ) {
      audioEl.current.currentTime -= 3;
    } else if (
      index !== nextPlayback &&
      audioEl !== null &&
      audioEl.current !== null
    ) {
      audioEl.current.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioBackwardSwitch]);

  useEffect(() => {
    if (
      index === nextPlayback &&
      audioEl !== null &&
      audioEl.current !== null
    ) {
      audioEl.current.currentTime -= 10;
    } else if (
      index !== nextPlayback &&
      audioEl !== null &&
      audioEl.current !== null
    ) {
      audioEl.current.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenBackwardSwitch]);

  useEffect(() => {
    if (
      index === nextPlayback &&
      audioEl !== null &&
      audioEl.current !== null
    ) {
      audioEl.current.currentTime -= 30;
    } else if (
      index !== nextPlayback &&
      audioEl !== null &&
      audioEl.current !== null
    ) {
      audioEl.current.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thirtyBackwardSwitch]);

  useEffect(() => {
    if (audioEl !== null && audioEl.current !== null) {
      audioEl.current.volume = audioValue / 100;
    }
  }, [audioValue]);

  useEffect(() => {
    if (audioEl !== null && audioEl.current !== null) {
      audioEl.current.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  const addForm = (arr: analysisResultType[]) => {
    arr.splice(index + 1, 0, {
      sentenceNumber: item.sentenceNumber,
      offset: item.offset,
      duration: item.duration,
      timeRange: item.timeRange,
      text: "",
      speaker: "",
      mainPoint: "",
      startTime: item.startTime,
      endTime: item.endTime,
    });
    return arr;
  };
  const onClickAddCell = () => {
    const arr = analysisData.slice();
    const a = addForm(arr);
    setAnalysisData(a);
  };
  const handleThemeColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let mainPointValue = "";
    if (editForm.mainPoint === "1") {
      mainPointValue = "0";
    } else {
      mainPointValue = "1";
    }
    const newData = { ...editForm, mainPoint: mainPointValue };
    setEditForm(newData);
  };
  return (
    <Box sx={{ mt: "8px" }}>
      <Typography>
        {formatNumberTime(item.startTime)} - {formatNumberTime(item.endTime)}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: "16px",
          mb: "8px",
        }}
      >
        {audioItem && analysisData.length > 0 && (
          <audio
            controls
            ref={audioEl}
            title={title + ".mp3"}
            onTimeUpdate={handleTimeUpdate}
            onPlay={(e: any) => {
              sessionStorage.setItem("newPage", "0");
              setNextPlayback(index);
            }}
            onRateChange={handlePlaybackRate}
            onLoadedData={handleTimeUpdate}
          >
            <source src={audioItem} />
            このブラウザはオーディオに対応していません
          </audio>
        )}
        {index === nextPlayback ? (
          <Badge color="secondary" variant="dot"></Badge>
        ) : (
          <></>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "20%",
            alignItems: "start",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "200px" }}>
            <Typography
              sx={{
                color: theme.defaultColor,
                fontSize: "16px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              }}
            >
              {textJa.common.speakername}
            </Typography>
            <StyledTextField
              id="name-basic"
              inputProps={{ maxLength: 60 }}
              placeholder={textJa.placeholder(textJa.common.speakername)}
              variant="standard"
              value={editForm.speaker}
              onChange={(e) =>
                setEditForm({
                  sentenceNumber: editForm.sentenceNumber,
                  offset: editForm.offset,
                  duration: editForm.duration,
                  timeRange: editForm.timeRange,
                  text: editForm.text,
                  speaker: e.target.value,
                  mainPoint: editForm.mainPoint,
                  startTime: editForm.startTime,
                  endTime: editForm.endTime,
                })
              }
            />
          </Box>
          <Box sx={{ width: "200px", marginTop: "50px" }}>
            <Typography
              sx={{
                color: theme.defaultColor,
                fontSize: "16px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              }}
            >
              {textJa.common.mainPoint}
              <input
                type="checkbox"
                id="mainPoint"
                checked={editForm.mainPoint === "1"}
                onChange={handleThemeColorChange}
              />
            </Typography>
          </Box>
        </Box>

        <Box sx={{ width: "78%", marginLeft: "2%" }}>
          <Typography
            sx={{
              color: theme.defaultColor,
              fontSize: "16px",
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            }}
          >
            {textJa.analysisEdit.analysisData}
          </Typography>
          <StyledMultiTextField
            id="outlined-multiline-static"
            multiline
            sx={{ width: "100%" }}
            inputProps={{ maxLength: 4000 }}
            rows={5}
            placeholder={textJa.placeholder(textJa.analysisEdit.analysisData)}
            value={editForm.text}
            onChange={(e) =>
              setEditForm({
                sentenceNumber: editForm.sentenceNumber,
                offset: editForm.offset,
                duration: editForm.duration,
                timeRange: editForm.timeRange,
                text: e.target.value,
                speaker: editForm.speaker,
                mainPoint: editForm.mainPoint,
                startTime: editForm.startTime,
                endTime: editForm.endTime,
              })
            }
          />
        </Box>
      </Box>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "start" }}
      >
        <IconButton
          size="large"
          color="inherit"
          aria-label="menu"
          onClick={onClickAddCell}
        >
          <Box
            sx={{
              borderRadius: "50%",
              backgroundColor: theme.gray300,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "40px",
              height: "20px",
            }}
          >
            <FeatherIcon
              icon="plus"
              color={theme.white}
              width="80%"
              height="80%"
            />
          </Box>
        </IconButton>
        <hr />
      </Box>
    </Box>
  );
};
