import { Box, Button, Modal, styled, Typography } from "@mui/material";
import { Dispatch, FC, SetStateAction } from "react";
import { textJa } from "../../locales/textJa";

type Props = {
  notDetailModalOpen: boolean;
  setNotDetailModalOpen: Dispatch<SetStateAction<boolean>>;
  word: string;
};

const StyledModal = styled(Modal)(() => ({
  "> .MuiBox-root": {
    border: "none",
    borderRadius: "16px",
    boxShadow: "0 0 8px gray",
    outline: "none",
  },
}));

export const NotOpenDetailModal: FC<Props> = ({
  notDetailModalOpen,
  setNotDetailModalOpen,
  word,
}) => {
  const onCloseModal = () => {
    setNotDetailModalOpen(false);
  };

  const onClickModal = () => {
    setNotDetailModalOpen(false);
  };

  return (
    <StyledModal
      open={notDetailModalOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          height: "15%",
          minHeight: "200px",
          maxHeight: "300px",
          width: "100%",
          maxWidth: "770px",
          display: "flex",
          flexFlow: "column",
          justifyContent: "space-around",
        }}
      >
        <Typography
          sx={{
            fontSize: "22px",
            fontWeight: 400,
            lineHeight: "26.63px",
            pb: "3.5%",
          }}
        >
          {word}
        </Typography>

        <Box
          sx={{
            textAlign: "right",
          }}
        >
          <Button
            onClick={onClickModal}
            sx={{
              fontSize: "22px",
              fontWeight: 400,
              lineHeight: "26.63px",
            }}
          >
            {textJa.home.ok}
          </Button>
        </Box>
      </Box>
    </StyledModal>
  );
};
