import {
  Box,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";
import {
  recordingFileAnalysisType,
  registerPeopleAddBoolType,
} from "../../types/type";
import { PullDown } from "../common/PullDown";
import { StyledCell, StyledHeader } from "../common/styles/tablecell";
import { StyledTextField } from "../common/styles/textField";
import { Record } from "./Record";
import { Row } from "./Row";

type Props = {
  open: boolean;
  handleClose: () => void;
  form: recordingFileAnalysisType;
  setForm: React.Dispatch<React.SetStateAction<recordingFileAnalysisType>>;
  registerPersonList: registerPeopleAddBoolType[];
  setRegisterPersonList: React.Dispatch<
    React.SetStateAction<registerPeopleAddBoolType[]>
  >;
  postPersonList: string;
  setPostPersonList: React.Dispatch<React.SetStateAction<string>>;
  siteList: string[];
  isWrite: boolean;
  setIsWrite: React.Dispatch<React.SetStateAction<boolean>>;
  setFinishModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const RealtimePerformance: React.FC<Props> = ({
  open,
  handleClose,
  form,
  setForm,
  registerPersonList,
  setRegisterPersonList,
  postPersonList,
  setPostPersonList,
  siteList,
  isWrite,
  setIsWrite,
  setFinishModal,
}) => {
  useEffect(() => {
    const selectPersonResult = registerPersonList.filter(
      (person) => person.select
    );
    let tmpList = "[";
    for (let i = 0; i < selectPersonResult.length; i++) {
      tmpList += '"' + selectPersonResult[i].speaker_id + '", ';
    }
    if (tmpList !== "[") {
      tmpList = tmpList.slice(0, -2);
    }
    tmpList += "]";
    setPostPersonList(tmpList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerPersonList]);

  const [allSelect, setAllSelect] = useState<boolean>(false);

  const handleClick = () => {
    setAllSelect(!allSelect);
    setRegisterPersonList(
      registerPersonList.map((data) => ({
        speaker_id: data.speaker_id,
        author: data.author,
        speaker_name: data.speaker_name,
        site_name: data.site_name,
        created_date: data.created_date,
        select: !allSelect,
      }))
    );
  };

  return (
    <Box>
      <Stack spacing={3}>
        <Typography
          sx={{
            color: theme.defaultColor,
            marginBottom: "-24px!important",
            fontSize: "16px",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          }}
        >
          {textJa.common.subject}
        </Typography>
        <StyledTextField
          id="name-basic"
          inputProps={{ maxLength: 60 }}
          placeholder={textJa.placeholder(textJa.common.subject)}
          variant="standard"
          value={form.title}
          onChange={(e) => setForm({ ...form, title: e.target.value })}
        />
        <PullDown
          pullDownItemList={siteList}
          setRegisterPersonList={setRegisterPersonList}
          form={form}
          setForm={setForm}
          setIsWrite={setIsWrite}
        />

        {isWrite && (
          <StyledTextField
            id="name-basic"
            inputProps={{ maxLength: 60 }}
            placeholder={textJa.placeholder(textJa.common.sitename)}
            variant="standard"
            value={form.sitename}
            onChange={(e) => setForm({ ...form, sitename: e.target.value })}
            sx={{ width: "100%" }}
          />
        )}
      </Stack>
      <Typography
        sx={{
          color: theme.defaultColor,
          fontSize: "16px",
          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          mt: "24px",
        }}
      >
        {textJa.recordingFileAnalysis.selectSpeaker}
      </Typography>

      <Table
        aria-label="sticky table"
        sx={{
          whiteSpace: "nowrap",
        }}
      >
        <TableHead sx={{ padding: "16px 8px" }}>
          <TableRow>
            <StyledCell>
              <Checkbox checked={allSelect} onClick={handleClick} />
            </StyledCell>
            <StyledCell>
              <StyledHeader>{textJa.common.speakername}</StyledHeader>
            </StyledCell>
            <StyledCell>
              <StyledHeader>{textJa.registerPerson.author}</StyledHeader>
            </StyledCell>
            <StyledCell>
              <StyledHeader>{textJa.registerPerson.createAt}</StyledHeader>
            </StyledCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {registerPersonList.map(
            (item, index) =>
              item && (
                <TableRow
                  key={item.speaker_id ? (item.speaker_id as string) : index}
                  sx={{ padding: "8px" }}
                >
                  <Row
                    item={item}
                    registerPersonList={registerPersonList}
                    setRegisterPersonList={setRegisterPersonList}
                  />
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
      <Record
        modalOpen={open}
        onCloseModal={handleClose}
        form={form}
        postPersonList={postPersonList}
        setFinishModal={setFinishModal}
      />
    </Box>
  );
};
