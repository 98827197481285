import { Box, Link } from "@mui/material";
import LogoAdmin from "../../images/MenuIcon.png";

export const LogoIcon = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Link href="/">
        <img src={LogoAdmin} alt={LogoAdmin} width={170} />
      </Link>
    </Box>
  );
};
