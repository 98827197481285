import React from "react";

export const NotFound = () => {
  return (
    <React.Fragment>
      <h1>404 NotFound</h1>
      <div>このページは存在しません。</div>
    </React.Fragment>
  );
};
