import VolumeDown from "@mui/icons-material/VolumeDown";
import VolumeUp from "@mui/icons-material/VolumeUp";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import FeatherIcon from "feather-icons-react";
import { theme } from "../../libs/theme";

type Props = {
  audioValue: number;
  setAudioValue: React.Dispatch<React.SetStateAction<number>>;
  audioStartSwitch: boolean;
  setAudioStartSwitch: React.Dispatch<React.SetStateAction<boolean>>;
  audioPauseSwitch: boolean;
  setAudioPauseSwitch: React.Dispatch<React.SetStateAction<boolean>>;
  removeEventListener: () => void;
};

export const AudioOversight: React.FC<Props> = ({
  audioValue,
  setAudioValue,
  audioStartSwitch,
  setAudioStartSwitch,
  audioPauseSwitch,
  setAudioPauseSwitch,
  removeEventListener,
}) => {
  const handleChange = (event: Event, newValue: number | number[]) => {
    removeEventListener();
    setAudioValue(newValue as number);
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "16px",
        justifyContent: "space-between",
      }}
    >
      <IconButton
        size="large"
        color="inherit"
        aria-label="menu"
        onClick={() => setAudioStartSwitch(!audioStartSwitch)}
      >
        <Box
          sx={{
            borderRadius: "50%",
            backgroundColor: theme.defaultColor,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "40px",
            height: "40px",
          }}
        >
          <Box sx={{ ml: "3px", mt: "6px" }}>
            <FeatherIcon
              icon="play"
              color={theme.white}
              width="80%"
              height="80%"
            />
          </Box>
        </Box>
      </IconButton>
      <IconButton
        size="large"
        color="inherit"
        aria-label="menu"
        onClick={() => setAudioPauseSwitch(!audioPauseSwitch)}
      >
        <Box
          sx={{
            borderRadius: "50%",
            backgroundColor: theme.defaultColor,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "40px",
            height: "40px",
          }}
        >
          <FeatherIcon
            icon="pause"
            color={theme.white}
            width="80%"
            height="80%"
          />
        </Box>
      </IconButton>
      <Box sx={{ width: 200 }}>
        <Stack spacing={2} direction="row" alignItems="center">
          <VolumeDown />
          <Slider
            aria-label="Volume"
            value={audioValue}
            onChange={handleChange}
          />
          <VolumeUp />
        </Stack>
      </Box>
    </Box>
  );
};
