export const textJa = {
  header: {
    profileDD: {
      title: "アカウント設定",
      settingCategory: {
        settingAccount: "アカウント設定",
      },
      menuItem: {
        settingMailAddress: "メールアドレス設定",
        changePassword: "パスワード変更",
      },
    },
  },
  LOGOUT: "ログアウト",
  dialog: (subject: string) => `${subject}を確定しますか？`,
  placeholder: (subject: string) => `${subject}を入力してください`,
  analysisStatus: {
    succeeded: "解析成功",
    failed: "解析失敗",
    conversion: "wav変換",
    transcription: "文字起こし",
    speaker: "話者識別",
    send: "受け渡し",
  },
  common: {
    subject: "タイトル",
    description: "説明",
    speakername: "話者名",
    mainPoint: "要点",
    cancelButton: "キャンセル",
    sitename: "現場名",
    selectFile: "ファイル選択",
    delete: "削除",
    filename: "ファイル名",
    notselect: "ファイルはまだ選択されていません",
    alertDir: "フォルダの入力は受け付けていません",
    dragArea: (selectFile: string) =>
      `ここに登録するファイルをドラッグアンドドロップするか、「${selectFile}」を選択します`,
  },
  home: {
    title: "解析履歴",
    id: "id",
    author: "文字起こし担当者",
    createAt: "作成日",
    length: "会議時間",
    status: "解析状況",
    delete: "削除",
    notOpen: "解析に成功していないデータの詳細は表示できません。",
    ok: "ok",
    searchPlaceHolder: "現場名",
    rowNumList: [10, 30, 50],
    rowNumTitle: "行数",
    reloadDetail:
      "最新の情報を見るために、常に再読み込み（更新）を行ってください。",
    reload: "再読み込み",
  },
  realtime: {
    title: "リアルタイム解析",
    registerButton: "解析を開始する",
    notOpen: "タイトル・現場名を入力してください。",
    finish1: "録音を終了しました。",
    finish2: "残りの進捗状況は解析履歴からご確認ください。",
  },
  record: {
    recording: "録音を開始しました。",
    stopping: "録音停止処理中です",
  },
  analysisEdit: {
    title: "解析内容編集",
    editButton: "編集を確定する",
    analysisData: "文字起こし内容",
    notOpen: "タイトル・現場名を入力してください。",
  },
  recordingFileAnalysis: {
    title: "録音ファイル解析",
    selectSpeaker: "話者選択",
    notOpen: "タイトル・現場名・音声ファイルを入力してください。",
    siteWrite: "現場名を手入力",
  },
  registerPerson: {
    title: "登録話者一覧",
    id: "id",
    author: "話者登録担当者",
    createAt: "作成日",
    delete: "削除",
  },
  registerCreate: {
    title: "話者登録",
    registerButton: "登録を確定する",
    smallTitle: "登録情報",
    notOpen: "話者名・現場名・音声ファイルを入力してください。",
  },
  contact: {
    title: "お問い合わせ",
    text1:
      "議事アシストに関しまして、ご不明な点がございましたら、下記までお問合せください。",
    text2:
      "お問合せ内容によっては回答までにお時間をいただく場合がございますので予めご了承ください。",
    text3: "引き続き、議事アシストをよろしくお願いいたします。",
    text4: "議事アシストインフォメーションデスク 0120-〇〇〇-〇〇〇",
    text5: "受付時間　10:00～17:00（土・日・祝日・年末年始を除く）",
    address: "hogehoge@hoge.com",
  },
  yes: "はい",
  no: "いいえ",
  login: {
    title: "ログイン",
    button: "ここからログインする",
  },
  pullDown: {
    title: "現場名",
  },
};
