import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { textJa } from "../../locales/textJa";
import { LoginButtonStyle } from "../common/styles/button";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const LoginButton = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType: any) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };
  return (
    <LoginButtonStyle onClick={() => handleLogin("redirect")}>
      {textJa.login.button}
    </LoginButtonStyle>
  );
};
