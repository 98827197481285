import { Checkbox, styled, TableCell, Typography } from "@mui/material";
import { FC } from "react";
import { theme } from "../../libs/theme";
import { registerPeopleAddBoolType } from "../../types/type";
type Props = {
  item: registerPeopleAddBoolType;
  registerPersonList: registerPeopleAddBoolType[];
  setRegisterPersonList: React.Dispatch<
    React.SetStateAction<registerPeopleAddBoolType[]>
  >;
};

const StyledTypography = styled(Typography)(() => ({
  color: theme.gray800,
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "21px",
}));

export const Row: FC<Props> = ({
  item,
  registerPersonList,
  setRegisterPersonList,
}) => {
  const handleClick = () => {
    setRegisterPersonList(
      registerPersonList.map((data) =>
        item.speaker_id === data.speaker_id
          ? {
              speaker_id: data.speaker_id,
              author: data.author,
              speaker_name: data.speaker_name,
              site_name: data.site_name,
              created_date: data.created_date,
              select: !data.select,
            }
          : data
      )
    );
  };
  return (
    <>
      <TableCell>
        <Checkbox checked={item.select} onClick={handleClick} />
      </TableCell>
      <TableCell>
        <StyledTypography
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "250px",
            overflow: "hidden",
          }}
        >
          {item.speaker_name}
        </StyledTypography>
      </TableCell>
      <TableCell>
        <StyledTypography
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "100px",
            overflow: "hidden",
          }}
        >
          {item.author}
        </StyledTypography>
      </TableCell>
      <TableCell>
        <StyledTypography
          sx={{
            maxWidth: "150px",
          }}
        >
          {item.created_date}
        </StyledTypography>
      </TableCell>
    </>
  );
};
