export const Menuitems = [
  {
    navlabel: true,
    subheader: "DASHBOARD",
    icon: "mdi mdi-dots-horizontal",
    href: "Dashboard",
    isWindowOpen: false,
  },
  {
    title: "解析履歴",
    icon: "home",
    href: "/",
    isWindowOpen: false,
  },
  {
    navlabel: true,
    subheader: "ANALYSIS",
    icon: "mdi mdi-dots-horizontal",
    href: "Dashboard",
    isWindowOpen: false,
  },
  {
    title: "録音ファイル解析",
    icon: "file-text",
    href: "/recording",
    isWindowOpen: false,
  },
  {
    title: "リアルタイム解析",
    icon: "clock",
    href: "/realtime",
    isWindowOpen: false,
  },
  {
    title: "話者登録",
    icon: "users",
    href: "/register",
    isWindowOpen: false,
  },
  {
    navlabel: true,
    subheader: "SUPPORT",
    icon: "mdi mdi-dots-horizontal",
    href: "Dashboard",
    isWindowOpen: false,
  },
  {
    title: "問い合わせ",
    icon: "message-circle",
    href: "/contact",
    isWindowOpen: false,
  },
  {
    title: "マニュアル",
    icon: "book-open",
    href: "https://jpn01.safelinks.protection.outlook.com/ap/w-59584e83/?url=https%3A%2F%2Fcoassistjp-my.sharepoint.com%2F%3Aw%3A%2Fg%2Fpersonal%2Finfo_coassistjp_onmicrosoft_com%2FEQBNz4LudVNHkfrx1p0vcAcB6nF4J1e1HoUGEAso1S4rcw%3Fe%3DNKBekg&data=05%7C01%7Cmitou%40fscreation.co.jp%7C64ca670f97584932662c08db4562de44%7C3ebd34a7816c4bc38f97faf9c38b5001%7C1%7C0%7C638180063159298068%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=dI9T%2FgBbyOWqyGddHjRy2HTuJDg5Sz5dAPj7jzztg4c%3D&reserved=0",
    isWindowOpen: true,
  },
];
