import { ContainerClient } from "@azure/storage-blob";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { Box, IconButton, Modal, styled, Typography } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import AudioRecorder from "../../libs/audioRecorder";
import { formatNumberTime } from "../../libs/formatTime";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";
import { UserContext } from "../../Main";
import {
  progressResponce,
  progressResult,
  recordingFileAnalysisType,
} from "../../types/type";
import { Loading } from "./Loading";
import "./styles/color.css";

const StyledModal = styled(Modal)(() => ({
  "> .MuiBox-root": {
    border: "none",
    borderRadius: "16px",
    boxShadow: "0 0 8px gray",
    outline: "none",
    zIndex: "999",
  },
}));

type Props = {
  modalOpen: boolean;
  onCloseModal: () => void;
  form: recordingFileAnalysisType;
  postPersonList: string;
  setFinishModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Record: React.FC<Props> = ({
  modalOpen,
  onCloseModal,
  form,
  postPersonList,
  setFinishModal,
}) => {
  const user = useContext(UserContext);
  const INTERVAL_SEC = Number(process.env.REACT_APP_INTERVAL_SEC);
  const [recorder, setRecorder] = useState<AudioRecorder>();
  const [timeIntervalId, setTimeIntervalId] = useState<NodeJS.Timeout | null>(
    null
  );
  const [recordID, setRecordID] = useState<string>("");
  let reID: string = recordID;
  const [serialNum, setSerialNum] = useState<number>(1);
  let seNum: number = serialNum;
  const [volumeTimeIntervalId, setVolumeTimeIntervalId] =
    useState<NodeJS.Timeout | null>(null);
  const [averageTimeIntervalId, setAverageTimeIntervalId] =
    useState<NodeJS.Timeout | null>(null);
  const [average, setAverage] = useState<number>(2);
  const [progressTimeIntervalId, setProgressTimeIntervalId] =
    useState<NodeJS.Timeout | null>(null);
  const [progressResultState, setProgressResultState] = useState<
    progressResult[]
  >([]);
  let progressSeNum: number = 1;
  let progressResult: progressResult[] = progressResultState;

  const [recordState, setRecordState] = useState<
    "isNotStarted" | "isRecording" | "isStopped" | "isStopping"
  >("isNotStarted");

  useEffect(() => {
    setRecorder(new AudioRecorder());
  }, []);

  // 録音中に画面を切り替えようとしたら警告表示。更新ボタン、URL入力、ブラウザの閉じるボタン
  const onReload = (e: BeforeUnloadEvent) => {
    if (sessionStorage.getItem("recordState") === "isRecording") {
      e.preventDefault();
      e.returnValue =
        "録音が停止されていません。停止をせずに画面を移動すると文字起こしが正しく行われない可能性があります。";
    }
  };

  // 録音中に画面を切り替えようとしたら警告表示。戻るボタン
  const onBackBt = (e: PopStateEvent) => {
    if (sessionStorage.getItem("recordState") === "isRecording") {
      var result = window.confirm(
        "録音が停止されていません。停止をせずに画面を移動すると文字起こしが正しく行われない可能性があります。"
      );
      if (result) {
      } else {
        alert("キャンセルされました。");
      }
    }
  };

  // Header Authorization
  const accessToken = user?.token;
  if (accessToken === "") {
    console.log("home Row: accessToken is null.");
  }
  let axiosInstance = axios.create({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  // レコードをスタートする関数
  const startRecording = () => {
    if (!recorder) return;
    // 初回スタートがまだの場合
    if (recordState === "isNotStarted") {
      setRecordState("isRecording");
      // 300分経ったら強制的にストップ
      setTimeout(() => {
        handleStopRecording();
      }, 5 * 60 * 60 * 1000); // 300min
    }
    recorder.start();

    // INTERVAL_SEC秒後に終了（ポーリング用）
    setTimeout(stopRecording, INTERVAL_SEC * 1000);
  };
  // startRecordingをポーリングするためのラッパー関数
  const handleStartRecording = () => {
    if (!recorder) return;
    startRecording();
    axiosInstance
      .get(
        `${process.env.REACT_APP_REGIST_AUDIO_ANALYSIS_LIST_API}&title=${form.title}&site_name=${form.sitename}&speaker_list=${postPersonList}&transcriptor_name=${user.email}`
      )
      .then((responce) => {
        setRecordID((_) => responce.data.id);
        reID = responce.data.id;
      });
    const intervalId = setInterval(() => {
      // startRecording();
    }, INTERVAL_SEC * 1000);
    setTimeIntervalId(intervalId);
    let sum = 0;
    const vol = recorder.getVolumes();
    sum = sum + vol;
    const volumeIntervalId = setInterval(() => {
      const vol = recorder.getVolumes();
      sum = sum + vol;
    }, 100);
    setVolumeTimeIntervalId(volumeIntervalId);
    const averageIntervalId = setInterval(() => {
      setAverage(sum / 18);
      sum = 0;
    }, 1800);
    setAverageTimeIntervalId(averageIntervalId);
    setTimeout(() => {
      const progressIntervalId = setInterval(() => {
        getProgress();
      }, 10 * 1000);
      setProgressTimeIntervalId(progressIntervalId);
    }, (INTERVAL_SEC + 30) * 1000);

    // 録音中に画面を切り替えようとしたら警告表示。
    window.history.pushState(null, "", null);
    window.addEventListener("beforeunload", onReload);
    window.addEventListener("popstate", onBackBt);
    sessionStorage.setItem("recordState", "isRecording");
  };

  // レコードをストップする関数
  const stopRecording = async () => {
    if (!recorder) return;
    const response = await recorder.stop();
    await startRecording();
    if (response?.blob) {
      const sasData: any = await getSas();
      if (!!sasData) {
        const sasUrl = sasData[0];
        const sasName = sasData[1];
        const containerClient = new ContainerClient(sasUrl);
        // const blobName = "new_amagasaki_output.wav";
        const blobName = sasName;
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);
        // // set mimetype as determined from browser with file upload control
        const options = {
          blobHTTPHeaders: { blobContentType: response?.blob.type },
        };
        try {
          await blockBlobClient.uploadData(response?.blob, options);

          await axiosInstance.get(
            `${
              process.env.REACT_APP_REGIST_AUDIO_ANALYSIS_DATA_DETAILS_API
            }&id=${reID}&serial_num=${seNum}&start_time=${
              (seNum - 1) * INTERVAL_SEC
            }&end_time=${seNum * INTERVAL_SEC}&blob_path=${sasName}`
          );
        } catch (err) {
          console.error(err);
        }
        seNum = seNum + 1;
        setSerialNum(seNum);
      }
    }
  };

  // レコードを強制終了するラッパー関数
  const handleStopRecording = async () => {
    // 録音停止ボタンを1度押した後は、録音停止ボタンを押せないようにする。
    if (recordState === "isStopping") {
      return;
    }
    setRecordState("isStopping");
    await stopRecording();
    await axiosInstance
      .get(
        `${
          process.env.REACT_APP_REGIST_START_AUDIO_ANALYSIS_API
        }&id=${reID}&total_num=${seNum - 1}`
      )
      .then(() => {})
      .catch(() => {});

    recorder?.finishTrack();

    // timeoutIdやtimeIntervalIdが残っている場合はクリアにする
    if (timeIntervalId) {
      clearInterval(timeIntervalId);
    }
    if (volumeTimeIntervalId) {
      clearInterval(volumeTimeIntervalId);
    }
    if (averageTimeIntervalId) {
      clearInterval(averageTimeIntervalId);
    }
    if (progressTimeIntervalId) {
      clearInterval(progressTimeIntervalId);
    }

    // 初期化
    // イベントリスナー取り外し
    window.removeEventListener("beforeunload", onReload);
    window.removeEventListener("popstate", onBackBt);
    sessionStorage.setItem("recordState", "isStopped");
    // レコーディング停止のダイアログの出力タイミングを停止前から停止後に変更
    setFinishModal(true);
    setRecordState("isStopped");
  };

  const getSas = async () => {
    const sasData = await axiosInstance
      .post(
        `${process.env.REACT_APP_RETURN_SAS_API}&blob_container_name=analysisaudio&audio_file_name=${form.title}_${seNum}.weba`
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });

    return sasData;
  };

  // 途中結果を取得する
  const getProgress = async () => {
    // detailsのserial_numにより、小さいserial_num小さいのを途中結果を取得する
    if (progressSeNum <= seNum) {
      const progressResponse: progressResponce = await axiosInstance
        .get(
          `${process.env.REACT_APP_GET_ANALYSIS_PROGRESS}&id=${reID}&serial_num=${progressSeNum}`
        )
        .then((response) => {
          return response.data;
        })
        .catch((response) => {
          return response.response.data;
        });

      if (!progressResponse.process_status) {
        progressSeNum += 1;
        if (typeof progressResponse.analysis_result !== "string") {
          progressResult = progressResult.concat(
            progressResponse.analysis_result.result
          );
          setProgressResultState(progressResult);
        }
      }
    }
  };

  return (
    <StyledModal
      open={modalOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        width="70vw"
        height="70vh"
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          alignItems: "center",

          justifyContent: "space-between",
        }}
      >
        <Box
          width="30%"
          height="100%"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Loading voiceVolume={average} recordState={recordState} />

          {recordState === "isNotStarted" && (
            <IconButton onClick={handleStartRecording} color="error">
              <RadioButtonCheckedIcon
                sx={{
                  height: "100px",
                  width: "100px",
                }}
              />
            </IconButton>
          )}
          {recordState === "isRecording" && (
            <IconButton onClick={handleStopRecording} color="error">
              <StopCircleIcon
                sx={{
                  height: "100px",
                  width: "100px",
                }}
              />
            </IconButton>
          )}
          {recordState === "isStopped" ? <></> : null}
          {recordState === "isStopping" && (
            <IconButton onClick={handleStopRecording} color="error">
              <StopCircleIcon
                sx={{
                  height: "100px",
                  width: "100px",
                }}
              />
            </IconButton>
          )}
          {recordState === "isRecording" && (
            <Typography
              color="error"
              sx={{
                height: "50px",
              }}
            >
              {textJa.record.recording}
            </Typography>
          )}
          {recordState === "isNotStarted" && (
            <Typography
              sx={{
                height: "50px",
              }}
            ></Typography>
          )}
          {recordState === "isStopping" && (
            <Typography
              color="error"
              sx={{
                height: "50px",
              }}
            >
              {textJa.record.stopping}
            </Typography>
          )}
        </Box>
        <Box width="70%" height="100%">
          <Box
            sx={{
              marginLeft: "30px",
              marginRight: "30px",
              paddingTop: "10px",
              paddingLeft: "10px",
              paddingRight: "10px",
              border: 4,
              borderRadius: "24px",
              borderColor: theme.gray800,
              height: "95%",
              width: "90%",
              overflow: "auto",
            }}
          >
            {progressResult.map((item, index) => (
              <Box key={index} sx={{ marginBottom: "16px" }}>
                <Typography>{`${formatNumberTime(
                  Math.floor(item.offset)
                )} ― ${formatNumberTime(
                  Math.floor(item.offset + item.duration)
                )}`}</Typography>
                <Typography>{item.speaker}</Typography>
                <Typography>{item.text}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </StyledModal>
  );
};
