import { ContainerClient } from "@azure/storage-blob";
import { Box, Button, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";
import { LoadingContext, UserContext } from "../../Main";
import { BaseModal } from "../common/BaseModal";
import { StyledTextField } from "../common/styles/textField";
import { SmallTitle } from "../common/styles/title";

type Props = {
  open: boolean;
  handleClose: () => void;
  files: File[] | FileList | null;
  setFiles: React.Dispatch<React.SetStateAction<File[] | FileList | null>>;
  form: string;
  setForm: React.Dispatch<React.SetStateAction<string>>;
  siteForm: string;
  setSiteForm: React.Dispatch<React.SetStateAction<string>>;
};

export const RegisterCreatePerformance: React.FC<Props> = ({
  open,
  handleClose,
  files,
  setFiles,
  form,
  setForm,
  siteForm,
  setSiteForm,
}) => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const { setIsLoading } = useContext(LoadingContext);

  const onClickRegister = async () => {
    if (!!files) {
      // Header Authorization
      const accessToken = user?.token;
      if (accessToken === "") {
        console.log("home Row: accessToken is null.");
      }
      let axiosInstance = axios.create({
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      handleClose();
      setIsLoading(true);
      const sasData = await axiosInstance
        .post(
          `${process.env.REACT_APP_RETURN_SAS_API}&blob_container_name=speakeraudio&audio_file_name=${files[0].name}`
        )
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error(err);
          return null;
        });

      if (!!sasData) {
        const sasUrl = sasData[0];
        const sasName = sasData[1];
        const containerClient = new ContainerClient(sasUrl);
        // const blobName = "new_amagasaki_output.wav";
        const blobName = sasName;

        const blockBlobClient = containerClient.getBlockBlobClient(blobName);

        // // set mimetype as determined from browser with file upload control
        const options = { blobHTTPHeaders: { blobContentType: files[0].type } };
        try {
          await blockBlobClient.uploadData(files[0], options);
          await axiosInstance.post(
            `${process.env.REACT_APP_REGIST_SPEAKER_TO_COSMOS_API}&speaker_name=${form}&site_name=${siteForm}&blob_path=${blobName}&create_user_name=${user.email}`
          );
        } catch {}
      }
      setIsLoading(false);
      navigate("/register");
    }
  };
  const inputRef = useRef<HTMLInputElement>(null);

  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFiles(event.target.files);
  };

  const fileUpload = () => {
    if (inputRef !== null && inputRef.current !== null) {
      inputRef.current.click();
    }
  };

  const resetFile = () => {
    setFiles(null);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
          mb: "8px",
          width: "100%",
          justifyContent: "start",
        }}
      >
        <SmallTitle>{textJa.registerCreate.smallTitle}</SmallTitle>
        <Button onClick={fileUpload}>{textJa.common.selectFile}</Button>
        <Button onClick={resetFile}>{textJa.common.delete}</Button>
        <input
          hidden
          ref={inputRef}
          type="file"
          onChange={onFileInputChange}
          accept="audio/*"
        />
      </Box>
      <Typography
        sx={{
          color: theme.defaultColor,
          fontSize: "16px",
          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          mp: "8px",
        }}
      >
        {textJa.common.filename}
      </Typography>
      <Typography
        sx={{
          color: theme.gray800,
          fontSize: "16px",
          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          mb: "16px",
        }}
      >
        {files === null || files.length === 0
          ? textJa.common.notselect
          : files[0].name}
      </Typography>
      <Stack spacing={3}>
        <Typography
          sx={{
            color: theme.defaultColor,
            marginBottom: "-24px!important",
            fontSize: "16px",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          }}
        >
          {textJa.common.speakername}
        </Typography>
        <StyledTextField
          id="name-basic"
          inputProps={{ maxLength: 60 }}
          placeholder={textJa.placeholder(textJa.common.subject)}
          variant="standard"
          value={form}
          onChange={(e) => setForm(e.target.value)}
        />
      </Stack>
      <Stack spacing={3} sx={{ mt: "24px" }}>
        <Typography
          sx={{
            color: theme.defaultColor,
            marginBottom: "-24px!important",
            fontSize: "16px",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          }}
        >
          {textJa.common.sitename}
        </Typography>
        <StyledTextField
          id="name-basic"
          inputProps={{ maxLength: 60 }}
          placeholder={textJa.placeholder(textJa.common.sitename)}
          variant="standard"
          value={siteForm}
          onChange={(e) => setSiteForm(e.target.value)}
        />
      </Stack>
      <BaseModal
        modalOpen={open}
        onCloseModal={handleClose}
        text={textJa.dialog(textJa.registerCreate.title)}
        onNoClick={handleClose}
        onYesClick={onClickRegister}
      />
    </Box>
  );
};
