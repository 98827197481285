import { ContainerClient } from "@azure/storage-blob";
import { Box } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AnalysisEditPerformance } from "../components/analysisEdit/AnalysisEditPerformance";
import { AudioOversight } from "../components/analysisEdit/AudioOversight";
import { BaseCard } from "../components/common/BaseCard";
import { Pagenation } from "../components/common/Pagenation";
import { ScrollToTop } from "../components/common/ScrollToTop";
import { CancelButton, SendButton } from "../components/common/styles/button";
import { TitleTypo } from "../components/common/styles/title";
import { NotOpenDetailModal } from "../components/home/NotOpenDetailModal";
import { textJa } from "../locales/textJa";
import { LoadingContext, UserContext } from "../Main";
import { analysisDataType, analysisResultType } from "../types/type";
//import { stream } from "exceljs";
//import { JSONObject } from "@azure/cosmos";
// import { isContext } from "vm";

type Params = {
  id: string;
};

export const AnalysisEdit = () => {
  const { id } = useParams<Params>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [allAnalysisData, setAllAnalysisData] = useState<
    analysisResultType[][]
  >([]);
  const [analysisData, setAnalysisData] = useState<analysisResultType[]>([]);
  const [allPageNum, setAllPageNum] = useState<number>(1);
  const [pageNum, setPageNum] = useState<number>(1);

  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [notDetailModalOpen, setNotDetailModalOpen] = useState<boolean>(false);
  const handleOpen = () => {
    if (form.title !== "" && form.sitename !== "") {
      if (allAnalysisData.length > 0) {
        setAllAnalysisData(
          allAnalysisData.map((data, index) =>
            index === pageNum - 1 ? analysisData : data
          )
        );
      }
      setOpen(true);
    } else {
      setNotDetailModalOpen(true);
    }
    // イベントリスナー取り外し
    window.removeEventListener("beforeunload", onReload);
    window.removeEventListener("popstate", onBackBt);
    sessionStorage.setItem("loadingCount", "first");
  };
  const handleClose = () => setOpen(false);
  const { setIsLoading } = useContext(LoadingContext);
  const [apiData, setApiData] = useState<analysisDataType>({
    id: "",
    title: "",
    site_name: "",
    created_date: "",
    mtg_time: "",
    transcriptor_name: "",
    analysis_status: "",
    audio_data_file_path: "",
    audio_data_analysis_file_path: "",
    result: {
      audioFileName: "",
      audioHashcode: "",
      recogStartTime: "",
      result: [],
    },
  });
  const [form, setForm] = useState<{
    title: string;
    sitename: string;
  }>({ title: apiData?.title, sitename: apiData?.site_name });
  const [audioItem, setAudioItem] = useState<any>("");
  const user = useContext(UserContext);

  // Header Authorization
  const accessToken = user?.token;
  if (accessToken === "") {
    console.log("home Row: accessToken is null.");
  }
  let axiosInstance = axios.create({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const getAnalysisData = async () => {
    const tmpData = await axiosInstance.get(
      `${process.env.REACT_APP_HISTORY_API}&username=${user.email}&id=${id}`
    );

    setApiData(tmpData?.data);
  };

  const [playbackRate, setPlaybackRate] = useState<number>(1);
  const [playbackRateSwitch, setPlaybackRateSwitch] = useState<number>(1);
  const [audioStartSwitch, setAudioStartSwitch] = useState<boolean>(false);
  const [audioPauseSwitch, setAudioPauseSwitch] = useState<boolean>(false);
  const [audioValue, setAudioValue] = useState<number>(30);
  const [audioForwardSwitch, setAudioForwardSwitch] = useState<boolean>(false);
  const [audioBackwardSwitch, setAudioBackwardSwitch] =
    useState<boolean>(false);
  const [tenForwardSwitch, setTenForwardSwitch] = useState<boolean>(false);
  const [tenBackwardSwitch, setTenBackwardSwitch] = useState<boolean>(false);
  const [thirtyForwardSwitch, setThirtyForwardSwitch] =
    useState<boolean>(false);
  const [thirtyBackwardSwitch, setThirtyBackwardSwitch] =
    useState<boolean>(false);

  const getSas = async () => {
    /**
     * sasから該当データを取得する
     *
     *
     */

    //----------------------------------------------------
    // sas取得
    //----------------------------------------------------
    const sasData = await axiosInstance
      .post(
        `${process.env.REACT_APP_RETURN_SAS_API}&blob_container_name=analysisaudio&audio_file_name=to-get-sas-dummy.wav}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });

    return sasData;
  };

  const getAudio = async () => {
    // 音声データファイルのキャッシュを利用できるようにsession strageを使ってSASを保持する
    const sessionSasData = sessionStorage.getItem("sessionSasData");
    let containerClient: any = null;
    if (sessionSasData === null) {
      // データ無しの場合、新しいSASを取得してsesson strageに保存
      const sasData: any = await getSas();
      const sasUrl = sasData[0];
      containerClient = new ContainerClient(sasUrl);
      sessionStorage.setItem("sessionSasData", sasUrl);
    } else {
      // データ有り尚且つ有効期限を過ぎている場合
      const url = new URL(sessionSasData);
      const params = new URLSearchParams(url.search);
      if (params !== null) {
        const expdateTxt = params.get("se");
        if (expdateTxt !== null) {
          const expdate = new Date(expdateTxt);
          expdate.setHours(expdate.getHours() - 9);
          const today = new Date();
          // データ有り尚且つ有効期限を過ぎている場合、新しいSASを取得してsesson strageに保存
          if (expdate < today) {
            const sasData: any = await getSas();
            const sasUrl = sasData[0];
            containerClient = new ContainerClient(sasUrl);
            sessionStorage.setItem("sessionSasData", sasUrl);
          } else {
            containerClient = new ContainerClient(sessionSasData);
          }
        }
      }
    }

    //----------------------------------------------------
    // 該当音声ファイル/解析結果jsonファイルパス取得
    //----------------------------------------------------

    const blobPathData: any = await axiosInstance.get(
      `${process.env.REACT_APP_DATA_PATH_API}&type=audio&id=${id}`
    );

    const blobDownload = async () => {
      const blobName: string = blobPathData.data;
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
      const downloadBlockBlobResponse: any = await blockBlobClient.download(0);
      const blobUrl = URL.createObjectURL(
        await downloadBlockBlobResponse.blobBody
      );
      sessionStorage.setItem("sessionBlobUrl_" + id, blobUrl);
      return blobUrl;
    };

    // 音声データファイルのキャッシュを利用できるようにsession strageを使ってblobUrlを保持する。
    const sessionBlobUrl = sessionStorage.getItem("sessionBlobUrl_" + id);
    let blobUrl = null;
    if (sessionBlobUrl === null) {
      blobUrl = await blobDownload();
    } else {
      await fetch(sessionBlobUrl)
        .then((r) => {
          blobUrl = sessionBlobUrl;
        })
        .catch(async (error) => {
          blobUrl = await blobDownload();
        });
    }
    setAudioItem(blobUrl);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await Promise.all([getAnalysisData(), getAudio()]);
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const createAnalysisData = () => {
    let tmpAnalysisData = [];
    if (
      apiData.result.result.length > 0 &&
      Object.keys(apiData.result.result[0]).includes("startTime")
    ) {
      tmpAnalysisData = apiData.result.result;
    } else {
      for (let i = 0; i < apiData.result.result.length; i++) {
        let a = apiData.result.result[i];

        a["startTime"] = a.offset;
        a["endTime"] = a.offset + a.duration;
        tmpAnalysisData.push(a);
        // if (i === 0) {
        //   a["startTime"] = 0;
        // } else {
        //   a["startTime"] = a.offset;
        // }
        // if (i < apiData.result.result.length - 1) {
        //   a["endTime"] = apiData.result.result[i + 1].offset;
        //   tmpAnalysisData.push(a);
        // } else {
        //   a["endTime"] = Number(apiData.mtg_time);
        //   tmpAnalysisData.push(a);
        // }
      }
    }
    let tmp = [];
    const part = 30;
    for (let i = 0; i < tmpAnalysisData.length; i += part) {
      tmp.push(tmpAnalysisData.slice(i, i + part));
    }
    setAllPageNum(tmp.length);
    if (tmp.length === 0) {
      tmp.push([]);
      setAllPageNum(1);
    }
    setAnalysisData(tmp[0]);
    return tmp;
  };

  const updateAnalysisData = (newPage: number) => {
    if (allAnalysisData.length > 0) {
      setAllAnalysisData(
        allAnalysisData.map((data, index) =>
          index === pageNum - 1 ? analysisData : data
        )
      );
      setPageNum(newPage);
      setAnalysisData(allAnalysisData[newPage - 1]);
    }
  };

  useEffect(() => {
    setAllAnalysisData(createAnalysisData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiData]);

  const removeEventListener = () => {
    document.removeEventListener("keydown", keyDown, true);
    document.removeEventListener("keyup", keyUp, true);
  };

  const addEventListener = () => {
    document.addEventListener("keydown", keyDown, true);
    document.addEventListener("keyup", keyUp, true);
  };

  let keyFlag = 0;
  const keyDown = (e: any) => {
    if (keyFlag === 0) {
      keyFlag = 1;

      if (!!e.ctrlKey && e.keyCode === 13) {
        removeEventListener();
        setAudioStartSwitch(!audioStartSwitch);
      }
      if (!!e.altKey && e.keyCode === 13) {
        removeEventListener();
        setAudioPauseSwitch(!audioPauseSwitch);
      }

      if (!!e.ctrlKey && e.keyCode === 188 && audioValue !== 0) {
        removeEventListener();
        setAudioValue(Math.max(audioValue - 10, 0));
      }
      if (!!e.ctrlKey && e.keyCode === 190 && audioValue !== 100) {
        removeEventListener();
        setAudioValue(Math.min(audioValue + 10, 100));
      }
      if (!!e.altKey && e.keyCode === 40 && playbackRate > 0.25) {
        removeEventListener();
        setPlaybackRate(Math.max(playbackRate - 0.25, 0.25));
      }
      if (!!e.altKey && e.keyCode === 38 && playbackRate < 2) {
        removeEventListener();
        setPlaybackRate(Math.min(playbackRate + 0.25, 2));
      }

      if (!e.altKey && !e.ctrlKey && !!e.shiftKey && e.keyCode === 39) {
        removeEventListener();
        setAudioForwardSwitch(!audioForwardSwitch);
      }
      if (!e.altKey && !e.ctrlKey && !!e.shiftKey && e.keyCode === 37) {
        removeEventListener();
        setAudioBackwardSwitch(!audioBackwardSwitch);
      }
      if (!!e.altKey && !e.ctrlKey && !!e.shiftKey && e.keyCode === 39) {
        removeEventListener();
        setTenForwardSwitch(!tenForwardSwitch);
      }
      if (!!e.altKey && !e.ctrlKey && !!e.shiftKey && e.keyCode === 37) {
        removeEventListener();
        setTenBackwardSwitch(!tenBackwardSwitch);
      }
      if (!!e.altKey && !!e.ctrlKey && !e.shiftKey && e.keyCode === 39) {
        removeEventListener();
        setThirtyForwardSwitch(!thirtyForwardSwitch);
      }
      if (!!e.altKey && !!e.ctrlKey && !e.shiftKey && e.keyCode === 37) {
        removeEventListener();
        setThirtyBackwardSwitch(!thirtyBackwardSwitch);
      }
      if (e.keyCode === 16) {
        keyFlag = 0;
      }
      if (e.keyCode === 17) {
        keyFlag = 0;
      }
      if (e.keyCode === 18) {
        keyFlag = 0;
      }
    }
  };

  const keyUp = (e: any) => {
    keyFlag = 0;
  };
  useEffect(() => {
    document.addEventListener("keydown", keyDown, true);
    document.addEventListener("keyup", keyUp, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    audioStartSwitch,
    audioPauseSwitch,
    audioForwardSwitch,
    audioBackwardSwitch,
    tenForwardSwitch,
    tenBackwardSwitch,
    thirtyForwardSwitch,
    thirtyBackwardSwitch,
    audioValue,
  ]);

  // 編集中に画面を切り替えようとしたら警告表示。キャンセルボタン
  const CancleExe = () => {
    var result = window.confirm(
      "編集が確定されていません。確定をせずに画面を移動すると変更内容が保存されない可能性があります。"
    );
    if (result) {
      navigate("/");
    } else {
      alert("キャンセルされました。");
    }
  };

  // 編集中に画面を切り替えようとしたら警告表示。更新ボタン、URL入力、ブラウザの閉じるボタン
  const onReload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue =
      "編集が確定されていません。確定をせずに画面を移動すると変更内容が保存されない可能性があります。";
  };

  // 編集中に画面を切り替えようとしたら警告表示。戻るボタン
  const onBackBt = (e: PopStateEvent) => {
    var result = window.confirm(
      "編集が確定されていません。確定をせずに画面を移動すると変更内容が保存されない可能性があります。"
    );
    if (result) {
    } else {
      alert("キャンセルされました。");
    }
  };

  useEffect(() => {
    // 編集中に画面を切り替えようとしたら警告表示
    window.history.pushState(null, "", null);
    if (sessionStorage.getItem("loadingCount") !== "second") {
      window.addEventListener("beforeunload", onReload);
      window.addEventListener("popstate", onBackBt);
      sessionStorage.setItem("loadingCount", "second");
    }
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
          mb: "8px",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <TitleTypo>{textJa.home.title}</TitleTypo>
      </Box>
      <Box
        sx={{
          position: "fixed",
          right: "100px",
          top: "100px",
          display: "flex",
          alignItems: "center",
          gap: "16px",
          zIndex: 99,
        }}
      >
        <AudioOversight
          audioValue={audioValue}
          setAudioValue={setAudioValue}
          audioStartSwitch={audioStartSwitch}
          setAudioStartSwitch={setAudioStartSwitch}
          audioPauseSwitch={audioPauseSwitch}
          setAudioPauseSwitch={setAudioPauseSwitch}
          removeEventListener={removeEventListener}
        />
        <CancelButton onClick={CancleExe}>
          {textJa.common.cancelButton}
        </CancelButton>
        <SendButton onClick={handleOpen}>
          {textJa.analysisEdit.editButton}
        </SendButton>
      </Box>
      <ScrollToTop pageNum={pageNum} />
      <BaseCard>
        <Box>
          <AnalysisEditPerformance
            allAnalysisData={allAnalysisData}
            analysisData={analysisData}
            setAnalysisData={setAnalysisData}
            audioItem={audioItem}
            open={open}
            handleClose={handleClose}
            audioFileName={apiData.result.audioFileName}
            audioHashcode={apiData.result.audioHashcode}
            recogStartTime={apiData.result.recogStartTime}
            id={id}
            apiData={apiData}
            form={form}
            setForm={setForm}
            playbackRate={playbackRate}
            setPlaybackRate={setPlaybackRate}
            playbackRateSwitch={playbackRateSwitch}
            setPlaybackRateSwitch={setPlaybackRateSwitch}
            audioStartSwitch={audioStartSwitch}
            audioPauseSwitch={audioPauseSwitch}
            audioValue={audioValue}
            audioForwardSwitch={audioForwardSwitch}
            audioBackwardSwitch={audioBackwardSwitch}
            tenForwardSwitch={tenForwardSwitch}
            tenBackwardSwitch={tenBackwardSwitch}
            thirtyForwardSwitch={thirtyForwardSwitch}
            thirtyBackwardSwitch={thirtyBackwardSwitch}
            removeEventListener={removeEventListener}
            addEventListener={addEventListener}
          />
          <Pagenation
            allPage={allPageNum}
            pageNum={pageNum}
            updateAnalysisData={updateAnalysisData}
          />
        </Box>
      </BaseCard>
      <NotOpenDetailModal
        notDetailModalOpen={notDetailModalOpen}
        setNotDetailModalOpen={setNotDetailModalOpen}
        word={textJa.analysisEdit.notOpen}
      />
    </Box>
  );
};
