import { Box, Typography } from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";
import { LoginButton } from "./LoginButton";

export const LoginHeader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "50px",
        height: "90%",
      }}
    >
      <Box>
        <Box
          sx={{
            width: "88px",
            height: "88px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: theme.gradOrange,
            m: "0 auto",
          }}
        >
          <FeatherIcon
            icon="file-text"
            size="38px"
            style={{
              color: "white",
              background: "transparent",
            }}
          />
        </Box>
        <Typography
          sx={{
            mt: 3,
            display: "inline-block",
            fontSize: 34,
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            background: theme.gradOrange,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontWeight: 700,
          }}
        >
          {textJa.login.title}
        </Typography>
      </Box>
      <LoginButton />
    </Box>
  );
};
