import { Box, Table, TableBody, TableHead, TableRow } from "@mui/material";
import React from "react";
import { textJa } from "../../locales/textJa";
import { historyDataType } from "../../types/type";
import { StyledCell, StyledHeader } from "../common/styles/tablecell";
import { Row } from "./Row";

type Props = {
  items: historyDataType[];
  getDataList: () => Promise<void>;
};

export const HomeListPerformance: React.FC<Props> = ({
  items,
  getDataList,
}) => {
  return (
    <Box sx={{ display: "flex" }}>
      <Table
        aria-label="sticky table"
        sx={{
          whiteSpace: "nowrap",
        }}
      >
        <TableHead sx={{ padding: "16px 8px" }}>
          <TableRow>
            <StyledCell>
              <StyledHeader>{textJa.home.id}</StyledHeader>
            </StyledCell>
            <StyledCell>
              <StyledHeader>{textJa.common.subject}</StyledHeader>
            </StyledCell>
            <StyledCell>
              <StyledHeader>{textJa.common.sitename}</StyledHeader>
            </StyledCell>
            <StyledCell>
              <StyledHeader>{textJa.home.createAt}</StyledHeader>
            </StyledCell>
            <StyledCell>
              <StyledHeader>{textJa.home.length}</StyledHeader>
            </StyledCell>
            <StyledCell>
              <StyledHeader>{textJa.home.status}</StyledHeader>
            </StyledCell>
            <StyledCell></StyledCell>
            <StyledCell></StyledCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(
            (item, index) =>
              item && (
                <TableRow
                  key={item.id ? (item.id as string) : index}
                  hover={true}
                  sx={{ padding: "8px" }}
                >
                  <Row item={item} getDataList={getDataList} />
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
    </Box>
  );
};
