import { Box, IconButton, Input } from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { FC, useState } from "react";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";

type Props = {
  setSearchKeyword: React.Dispatch<React.SetStateAction<string>>;
};

export const Search: FC<Props> = ({ setSearchKeyword }) => {
  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setSearchKeyword(keyword);
    }
  };

  const handleButtonClick = () => {
    setSearchKeyword(keyword);
  };

  const [keyword, setKeyword] = useState<string>("");

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0px",
        margin: "8px 0",
        backgroundColor: theme.white,
        width: "345px",
        borderRadius: "12px",
        border: `1px solid ${theme.white}`,
        boxShadow: `0px 7px 30px ${theme.cardShadow}`,
      }}
    >
      <IconButton type="submit" aria-label="search" onClick={handleButtonClick}>
        <FeatherIcon icon="search" />
      </IconButton>
      <Input
        disableUnderline
        placeholder={textJa.home.searchPlaceHolder}
        sx={{ width: "100%" }}
        type="text"
        name="searchKeyword"
        onChange={(e) => {
          setKeyword(e.target.value);
        }}
        onKeyPress={(e) => handleKeyPress(e)}
      />
    </Box>
  );
};
