import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";
import {
  recordingFileAnalysisType,
  registerPeopleAddBoolType,
} from "../../types/type";
type Props = {
  pullDownItemList: string[];
  setRegisterPersonList: React.Dispatch<
    React.SetStateAction<registerPeopleAddBoolType[]>
  >;
  form: recordingFileAnalysisType; //serviceId or null
  setForm: React.Dispatch<React.SetStateAction<recordingFileAnalysisType>>;
  setIsWrite: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PullDown: React.FC<Props> = ({
  pullDownItemList,
  setRegisterPersonList,
  form,
  setForm,
  setIsWrite,
}) => {
  const handleOnChange = (e: any) => {
    if (e.target.value === textJa.recordingFileAnalysis.siteWrite) {
      setForm({ ...form, sitename: "" });
      setIsWrite(true);
      setRegisterPersonList([]);
    } else {
      setIsWrite(false);
      setForm({ ...form, sitename: e.target.value });
    }
  };
  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel
        sx={{
          width: "100%",
          color: theme.gray800,
        }}
      >
        {textJa.pullDown.title}
      </InputLabel>
      {pullDownItemList && (
        <Select
          label={textJa.pullDown.title} //表示したいタイトルの長さ分、枠確保
          value={form?.sitename}
          onChange={handleOnChange}
          sx={{
            backgroundColor: theme.white,
            color: theme.gray800,
          }}
        >
          {pullDownItemList.map((item, index) => (
            <MenuItem value={item} key={index}>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {`${item}`}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};
