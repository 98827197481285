import { Box } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { SendButton } from "../components/common/styles/button";
import { TitleTypo } from "../components/common/styles/title";
import { NotOpenDetailModal } from "../components/home/NotOpenDetailModal";
import { FinishMordal } from "../components/realtime/FinishModal";
import { RealtimePerformance } from "../components/realtime/RealtimePerformance";
import { textJa } from "../locales/textJa";
import { LoadingContext, UserContext } from "../Main";
import {
  recordingFileAnalysisType,
  registerPeopleAddBoolType,
} from "../types/type";

export const Realtime: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [notDetailModalOpen, setNotDetailModalOpen] = useState<boolean>(false);
  const [finishModal, setFinishModal] = useState<boolean>(false);
  const [isWrite, setIsWrite] = useState<boolean>(false);
  const handleOpen = () => {
    if (form.title !== "" && form.sitename !== "") {
      setOpen(true);
    } else {
      setNotDetailModalOpen(true);
    }
  };
  const handleClose = () => {};
  const { setIsLoading } = useContext(LoadingContext);
  const user = useContext(UserContext);
  const [form, setForm] = useState<recordingFileAnalysisType>({
    title: "",
    sitename: "",
  });
  const [siteList, setSiteList] = useState<string[]>([]);

  const [registerPersonList, setRegisterPersonList] = useState<
    registerPeopleAddBoolType[]
  >([]);

  const [postPersonList, setPostPersonList] = useState<string>("[]");

  const createDataset = (data: registerPeopleAddBoolType[]) => {
    let dataset = [];
    for (let i = 0; i < data.length; i++) {
      dataset.push(
        Object.assign(data[i], {
          author: user.familyName + " " + user.givenName,
          select: false,
        })
      );
    }
    return dataset;
  };

  const [loadCompleted, setLoadCompleted] = useState<boolean>(false);
  // Header Authorization
  const accessToken = user?.token;
  if (accessToken === "") {
    console.log("home Row: accessToken is null.");
  }
  let axiosInstance = axios.create({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const getSiteUser = async () => {
    setIsLoading(true);
    if (form.sitename !== "") {
      const tmpData = await axiosInstance.get(
        `${process.env.REACT_APP_LIST_BY_GEMBA_API}&username=${user.email}&site_name=${form.sitename}`
      );
      const dataset: registerPeopleAddBoolType[] = await createDataset(
        tmpData.data.data
      );
      setRegisterPersonList(dataset);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const getSiteName = async () => {
    setIsLoading(true);
    const tmpData = await axiosInstance.get(
      `${process.env.REACT_APP_GEMBA_API}&username=${user.email}`
    );
    tmpData?.data.push(textJa.recordingFileAnalysis.siteWrite);
    setSiteList(tmpData?.data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isWrite) {
      getSiteUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.sitename]);

  useEffect(() => {
    if (accessToken && !loadCompleted) {
      getSiteName();
      setLoadCompleted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
          mb: "8px",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <TitleTypo>{textJa.realtime.title}</TitleTypo>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <SendButton onClick={handleOpen}>
            {textJa.realtime.registerButton}
          </SendButton>
        </Box>
      </Box>
      <RealtimePerformance
        open={open}
        handleClose={handleClose}
        form={form}
        setForm={setForm}
        registerPersonList={registerPersonList}
        setRegisterPersonList={setRegisterPersonList}
        postPersonList={postPersonList}
        setPostPersonList={setPostPersonList}
        siteList={siteList}
        isWrite={isWrite}
        setIsWrite={setIsWrite}
        setFinishModal={setFinishModal}
      />
      <NotOpenDetailModal
        notDetailModalOpen={notDetailModalOpen}
        setNotDetailModalOpen={setNotDetailModalOpen}
        word={textJa.realtime.notOpen}
      />
      <FinishMordal finishModal={finishModal} />
    </>
  );
};
