export const extension = (mineType: string) => {
  switch (true) {
    case mineType.indexOf("ogg") !== -1:
      return "ogg";
    case mineType.indexOf("wav") !== -1:
      return "wav";
    case mineType.indexOf("mpeg") !== -1:
      return "mp3";
    default:
      return "webm";
  }
};
