import { Box } from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { textJa } from "../../locales/textJa";
import { LoadingContext, UserContext } from "../../Main";
import { analysisDataType, analysisResultType } from "../../types/type";
import { BaseModal } from "../common/BaseModal";
import { Row } from "./Row";
import { TitleForm } from "./TitleForm";

type Props = {
  allAnalysisData: analysisResultType[][];
  analysisData: analysisResultType[];
  setAnalysisData: React.Dispatch<React.SetStateAction<analysisResultType[]>>;
  audioItem: any;
  open: boolean;
  handleClose: () => void;
  audioFileName: string;
  audioHashcode: string;
  recogStartTime: string;
  id: string | undefined;
  apiData: analysisDataType;
  form: {
    title: string;
    sitename: string;
  };
  setForm: React.Dispatch<
    React.SetStateAction<{
      title: string;
      sitename: string;
    }>
  >;
  playbackRate: number;
  setPlaybackRate: React.Dispatch<React.SetStateAction<number>>;
  playbackRateSwitch: number;
  setPlaybackRateSwitch: React.Dispatch<React.SetStateAction<number>>;
  audioStartSwitch: boolean;
  audioPauseSwitch: boolean;
  audioValue: number;
  audioForwardSwitch: boolean;
  audioBackwardSwitch: boolean;
  tenForwardSwitch: boolean;
  tenBackwardSwitch: boolean;
  thirtyForwardSwitch: boolean;
  thirtyBackwardSwitch: boolean;
  removeEventListener: () => void;
  addEventListener: () => void;
};

export const AnalysisEditPerformance: React.FC<Props> = ({
  allAnalysisData,
  analysisData,
  setAnalysisData,
  audioItem,
  open,
  handleClose,
  audioFileName,
  audioHashcode,
  recogStartTime,
  id,
  apiData,
  form,
  setForm,
  playbackRate,
  setPlaybackRate,
  playbackRateSwitch,
  setPlaybackRateSwitch,
  audioStartSwitch,
  audioPauseSwitch,
  audioValue,
  audioForwardSwitch,
  audioBackwardSwitch,
  tenForwardSwitch,
  tenBackwardSwitch,
  thirtyForwardSwitch,
  thirtyBackwardSwitch,
  removeEventListener,
  addEventListener,
}) => {
  const [nextPlayback, setNextPlayback] = useState<number | null>(0);

  const navigate = useNavigate();
  const { setIsLoading } = useContext(LoadingContext);
  const user = useContext(UserContext);

  const onClickEdit = async () => {
    setIsLoading(true);
    handleClose();
    const sendData = {
      audioFileName: audioFileName,
      audioHashcode: audioHashcode,
      recogStartTime: recogStartTime,
      result: allAnalysisData.flat(),
    };

    // header Authorization
    const accessToken = user?.token;
    if (accessToken === "") {
      console.log("home Row: accessToken is null.");
    }
    let axiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    await axiosInstance.post(
      `${process.env.REACT_APP_ANALYSIS_RESULT_UPLOAD_API}&id=${id}&title=${form.title}&site_name=${form.sitename}`,
      sendData
    );
    setIsLoading(false);
    navigate("/");
  };

  return (
    <Box>
      <TitleForm form={form} setForm={setForm} apiData={apiData} />
      {analysisData.map((item, index) => (
        <React.Fragment key={index}>
          <Row
            item={item}
            audioItem={audioItem}
            index={index}
            analysisData={analysisData}
            setAnalysisData={setAnalysisData}
            nextPlayback={nextPlayback}
            setNextPlayback={setNextPlayback}
            playbackRate={playbackRate}
            setPlaybackRate={setPlaybackRate}
            playbackRateSwitch={playbackRateSwitch}
            setPlaybackRateSwitch={setPlaybackRateSwitch}
            audioStartSwitch={audioStartSwitch}
            audioPauseSwitch={audioPauseSwitch}
            audioForwardSwitch={audioForwardSwitch}
            audioBackwardSwitch={audioBackwardSwitch}
            tenForwardSwitch={tenForwardSwitch}
            tenBackwardSwitch={tenBackwardSwitch}
            thirtyForwardSwitch={thirtyForwardSwitch}
            thirtyBackwardSwitch={thirtyBackwardSwitch}
            audioValue={audioValue}
            removeEventListener={removeEventListener}
            addEventListener={addEventListener}
            title={apiData.title}
          />
        </React.Fragment>
      ))}
      <BaseModal
        modalOpen={open}
        onCloseModal={handleClose}
        text={textJa.dialog(textJa.analysisEdit.title)}
        onNoClick={handleClose}
        onYesClick={onClickEdit}
      />
    </Box>
  );
};
