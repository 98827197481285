import { ContainerClient } from "@azure/storage-blob";
import {
  Box,
  Button,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";
import { LoadingContext, UserContext } from "../../Main";
import {
  recordingFileAnalysisType,
  registerPeopleAddBoolType,
} from "../../types/type";
import { BaseModal } from "../common/BaseModal";
import { PullDown } from "../common/PullDown";
import { StyledCell, StyledHeader } from "../common/styles/tablecell";
import { StyledTextField } from "../common/styles/textField";
import { SmallTitle } from "../common/styles/title";
import { Row } from "./Row";

type Props = {
  open: boolean;
  handleClose: () => void;
  files: File[] | FileList | null;
  setFiles: React.Dispatch<React.SetStateAction<File[] | FileList | null>>;
  form: recordingFileAnalysisType;
  setForm: React.Dispatch<React.SetStateAction<recordingFileAnalysisType>>;
  registerPersonList: registerPeopleAddBoolType[];
  setRegisterPersonList: React.Dispatch<
    React.SetStateAction<registerPeopleAddBoolType[]>
  >;
  postPersonList: string;
  setPostPersonList: React.Dispatch<React.SetStateAction<string>>;
  siteList: string[];
  isWrite: boolean;
  setIsWrite: React.Dispatch<React.SetStateAction<boolean>>;
};

export const RecordingFileAnalysisPerformance: React.FC<Props> = ({
  open,
  handleClose,
  files,
  setFiles,
  form,
  setForm,
  registerPersonList,
  setRegisterPersonList,
  postPersonList,
  setPostPersonList,
  siteList,
  isWrite,
  setIsWrite,
}) => {
  const { setIsLoading } = useContext(LoadingContext);
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const getAudioTime = (file: string): Promise<number> => {
    var audio = document.createElement("audio");
    return new Promise((resolve) => {
      audio.src = file as string;
      audio.addEventListener("loadedmetadata", function () {
        const duration = audio.duration;
        resolve(duration);
      });
    });
  };

  const onClickRegister = async () => {
    if (!!files) {
      // Header Authorization
      const accessToken = user?.token;
      if (accessToken === "") {
        console.log("home Row: accessToken is null.");
      }
      let axiosInstance = axios.create({
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      handleClose();
      setIsLoading(true);
      const sasData = await axiosInstance
        .post(
          `${process.env.REACT_APP_RETURN_SAS_API}&blob_container_name=analysisaudio&audio_file_name=${files[0].name}`
        )
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error(err);
          return null;
        });

      if (!!sasData) {
        const sasUrl = sasData[0];
        const sasName = sasData[1];
        const containerClient = new ContainerClient(sasUrl);
        // const blobName = "new_amagasaki_output.wav";
        const blobName = sasName;

        const blockBlobClient = containerClient.getBlockBlobClient(blobName);

        // // set mimetype as determined from browser with file upload control
        const options = { blobHTTPHeaders: { blobContentType: files[0].type } };
        try {
          await blockBlobClient.uploadData(files[0], options);
          const objectURL = URL.createObjectURL(files[0]);
          const audioTime = await getAudioTime(objectURL);
          await axiosInstance.post(
            `${process.env.REACT_APP_REGIST_AUDIO_ANALYSIS_API}&title=${form.title}&site_name=${form.sitename}&analysis_status=wav変換中&blob_path=${blobName}&mtg_time=${audioTime}&speaker_list=${postPersonList}&transcriptor_name=${user.email}`
          );
        } catch (err) {
          console.error(err);
        }

        navigate("/");
        setIsLoading(false);
      }
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFiles(event.target.files);
  };

  const fileUpload = () => {
    if (inputRef !== null && inputRef.current !== null) {
      inputRef.current.click();
    }
  };

  const resetFile = () => {
    setFiles(null);
  };

  useEffect(() => {
    const selectPersonResult = registerPersonList.filter(
      (person) => person.select
    );
    let tmpList = "[";
    for (let i = 0; i < selectPersonResult.length; i++) {
      tmpList += '"' + selectPersonResult[i].speaker_id + '", ';
    }
    if (tmpList !== "[") {
      tmpList = tmpList.slice(0, -2);
    }
    tmpList += "]";
    setPostPersonList(tmpList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerPersonList]);

  const [allSelect, setAllSelect] = useState<boolean>(false);

  const handleClick = () => {
    setAllSelect(!allSelect);
    setRegisterPersonList(
      registerPersonList.map((data) => ({
        speaker_id: data.speaker_id,
        author: data.author,
        speaker_name: data.speaker_name,
        site_name: data.site_name,
        created_date: data.created_date,
        select: !allSelect,
      }))
    );
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
          mb: "8px",
          width: "100%",
          justifyContent: "start",
        }}
      >
        <SmallTitle>{textJa.registerCreate.smallTitle}</SmallTitle>
        <Button onClick={fileUpload}>{textJa.common.selectFile}</Button>
        <Button onClick={resetFile}>{textJa.common.delete}</Button>
        <input
          hidden
          ref={inputRef}
          type="file"
          onChange={onFileInputChange}
          accept="audio/*"
        />
      </Box>
      <Typography
        sx={{
          color: theme.defaultColor,
          fontSize: "16px",
          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          mp: "8px",
        }}
      >
        {textJa.common.filename}
      </Typography>
      <Typography
        sx={{
          color: theme.gray800,
          fontSize: "16px",
          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          mb: "24px",
        }}
      >
        {files === null || files.length === 0
          ? textJa.common.notselect
          : files[0].name}
      </Typography>
      <Stack spacing={3}>
        <Typography
          sx={{
            color: theme.defaultColor,
            marginBottom: "-24px!important",
            fontSize: "16px",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          }}
        >
          {textJa.common.subject}
        </Typography>
        <StyledTextField
          id="name-basic"
          inputProps={{ maxLength: 60 }}
          placeholder={textJa.placeholder(textJa.common.subject)}
          variant="standard"
          value={form.title}
          onChange={(e) => setForm({ ...form, title: e.target.value })}
        />
        <PullDown
          pullDownItemList={siteList}
          setRegisterPersonList={setRegisterPersonList}
          form={form}
          setForm={setForm}
          setIsWrite={setIsWrite}
        />

        {isWrite && (
          <StyledTextField
            id="name-basic"
            inputProps={{ maxLength: 60 }}
            placeholder={textJa.placeholder(textJa.common.sitename)}
            variant="standard"
            value={form.sitename}
            onChange={(e) => setForm({ ...form, sitename: e.target.value })}
            sx={{ width: "100%" }}
          />
        )}
      </Stack>
      <Typography
        sx={{
          color: theme.defaultColor,
          fontSize: "16px",
          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          mt: "24px",
        }}
      >
        {textJa.recordingFileAnalysis.selectSpeaker}
      </Typography>

      <Table
        aria-label="sticky table"
        sx={{
          whiteSpace: "nowrap",
        }}
      >
        <TableHead sx={{ padding: "16px 8px" }}>
          <TableRow>
            <StyledCell>
              <Checkbox checked={allSelect} onClick={handleClick} />
            </StyledCell>
            <StyledCell>
              <StyledHeader>{textJa.common.speakername}</StyledHeader>
            </StyledCell>
            <StyledCell>
              <StyledHeader>{textJa.registerPerson.author}</StyledHeader>
            </StyledCell>
            <StyledCell>
              <StyledHeader>{textJa.registerPerson.createAt}</StyledHeader>
            </StyledCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {registerPersonList.map(
            (item, index) =>
              item && (
                <TableRow
                  key={item.speaker_id ? (item.speaker_id as string) : index}
                  sx={{ padding: "8px" }}
                >
                  <Row
                    item={item}
                    registerPersonList={registerPersonList}
                    setRegisterPersonList={setRegisterPersonList}
                  />
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
      <BaseModal
        modalOpen={open}
        onCloseModal={handleClose}
        text={textJa.dialog(textJa.recordingFileAnalysis.title)}
        onNoClick={handleClose}
        onYesClick={onClickRegister}
      />
    </Box>
  );
};
