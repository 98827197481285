import { IconButton, styled, TableCell, Typography } from "@mui/material";
import axios from "axios";
import FeatherIcon from "feather-icons-react";
import { FC, useContext, useState } from "react";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";
import { LoadingContext, UserContext } from "../../Main";
import { registerPeopleType } from "../../types/type";
import { BaseModal } from "../common/BaseModal";

type Props = {
  item: registerPeopleType;
  getSpeaker: () => Promise<void>;
};

const StyledTypography = styled(Typography)(() => ({
  color: theme.gray800,
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "21px",
}));

export const Row: FC<Props> = ({ item, getSpeaker }) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const user = useContext(UserContext);
  const { setIsLoading } = useContext(LoadingContext);

  const onClickDelete = async () => {
    handleClose();
    setIsLoading(true);

    // Header Authorization
    const accessToken = user?.token;
    if (accessToken === "") {
      console.log("home Row: accessToken is null.");
    }
    let axiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    await axiosInstance
      .post(`${process.env.REACT_APP_SPEAKER_DELETE_API}&id=${item.id}`)
      .then(() => {
        setTimeout(() => {
          getSpeaker();
          setIsLoading(false);
        }, 2000);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };
  return (
    <>
      <TableCell>
        <StyledTypography
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "48px",
            overflow: "hidden",
          }}
        >
          {item.id}
        </StyledTypography>
      </TableCell>
      <TableCell>
        <StyledTypography
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "250px",
            overflow: "hidden",
          }}
        >
          {item.speaker_name}
        </StyledTypography>
      </TableCell>
      <TableCell>
        <StyledTypography
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "250px",
            overflow: "hidden",
          }}
        >
          {item.site_name}
        </StyledTypography>
      </TableCell>
      <TableCell>
        <StyledTypography
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "100px",
            overflow: "hidden",
          }}
        >
          {user.familyName + " " + user.givenName}
        </StyledTypography>
      </TableCell>
      <TableCell>
        <StyledTypography
          sx={{
            maxWidth: "150px",
          }}
        >
          {item.created_date}
        </StyledTypography>
      </TableCell>
      <TableCell>
        <IconButton
          size="large"
          aria-label="menu"
          onClick={handleOpen}
          sx={{
            display: "flex",
          }}
        >
          <FeatherIcon icon="trash-2" />
        </IconButton>
      </TableCell>
      <BaseModal
        modalOpen={open}
        onCloseModal={handleClose}
        text={textJa.dialog(textJa.home.delete)}
        onNoClick={handleClose}
        onYesClick={onClickDelete}
      />
    </>
  );
};
