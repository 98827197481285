import clsx from "clsx";
import styles from "./styles/Loading.module.scss";

type Props = {
  voiceVolume: number;
  recordState: string;
};
export const Loading: React.FC<Props> = ({ voiceVolume, recordState }) => {
  const averageToStyle = (average: number) => {
    switch (true) {
      case average <= 1 && average > 0:
        return "small";
      case average <= 2 && average > 1:
        return "medium";
      case average <= 3 && average > 2:
        return "base";
      case average > 3:
        return "big";
      default:
        return "small";
    }
  };

  return (
    <>
      <div className={styles.loadingWrapper}>
        {recordState === "isRecording" ? (
          <>
            {[0, 1, 2, 3, 4, 5].map((_, index) => {
              return (
                <div
                  key={index}
                  className={clsx(
                    styles.border,
                    styles[averageToStyle(voiceVolume)]
                  )}
                />
              );
            })}
          </>
        ) : (
          <>
            {[0, 1, 2, 3, 4, 5].map((_, index) => {
              return <div key={index} className={styles.stopborder} />;
            })}
          </>
        )}
      </div>
    </>
  );
};
