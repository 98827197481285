import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import FeatherIcon from "feather-icons-react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { theme } from "../../libs/theme";
import { LogoIcon } from "./LogoIcon";
import { Menuitems } from "./MenuItems";

type Props = {
  onSidebarClose: () => void;
  isSidebarOpen: boolean;
};

export const SideMenu: React.FC<Props> = ({
  onSidebarClose,
  isSidebarOpen,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = (link: string, isWindowOpen: boolean) => {
    if (isWindowOpen) {
      window.open(link, "_blank", "noreferrer");
    } else {
      navigate(link);
    }
  };
  const isSelected = (path: string) => {
    if (path === "/") {
      return !!matchPath("/", location.pathname);
    }
    return !!matchPath(`${path}/*`, location.pathname);
  };

  const SidebarContent = (
    <Box p={2} height="100%">
      <LogoIcon />
      <Box mt={"16px"}>
        <List disablePadding={true}>
          {Menuitems.map((item, index) => {
            if (item.subheader) {
              return (
                <li key={item.subheader}>
                  <Typography
                    variant="subtitle2"
                    fontWeight="500"
                    sx={{
                      my: 2,
                      mt: index === 0 ? 0 : 4, //"DASHBOARD"の上だけmargin-top=0
                      opacity: "0.4",
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      color: theme.gray800,
                    }}
                  >
                    {item.subheader}
                  </Typography>
                </li>
              );
            } else if (item.isWindowOpen) {
              return (
                <List component="li" disablePadding key={item.title}>
                  <ListItem
                    onClick={() => handleClick(item.href, item.isWindowOpen)}
                    button
                    selected={isSelected(item.href)}
                    sx={{
                      mb: 1,
                      color: theme.gray800,
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      ...(isSelected(item.href) && {
                        color: theme.white,
                        backgroundColor: `${theme.defaultColor}!important`,
                      }),
                      borderRadius: 2,
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: "40px!important" }}>
                      <FeatherIcon icon={item.icon} width="20" height="20" />
                    </ListItemIcon>

                    <ListItemText>{item.title}</ListItemText>
                    <ListItemIcon sx={{ minWidth: "18px!important" }}>
                      <FeatherIcon
                        icon="external-link"
                        width="18"
                        height="18"
                      />
                    </ListItemIcon>
                  </ListItem>
                </List>
              );
            } else {
              return (
                <List component="li" disablePadding key={item.title}>
                  <ListItem
                    onClick={() => handleClick(item.href, item.isWindowOpen)}
                    button
                    selected={isSelected(item.href)}
                    sx={{
                      mb: 1,
                      color: theme.gray800,
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      ...(isSelected(item.href) && {
                        color: theme.white,
                        backgroundColor: `${theme.defaultColor}!important`,
                      }),
                      borderRadius: 2,
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: "40px!important" }}>
                      <FeatherIcon
                        icon={item.icon}
                        width="20"
                        height="20"
                        style={{
                          color: `${isSelected(item.href) ? "white" : ""} `,
                        }}
                      />
                    </ListItemIcon>

                    <ListItemText>{item.title}</ListItemText>
                  </ListItem>
                </List>
              );
            }
          })}
        </List>
      </Box>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      open={isSidebarOpen}
      onClose={onSidebarClose}
      variant="persistent"
      PaperProps={{
        sx: {
          width: "265px",
          border: "0 !important",
          boxShadow: "0px 7px 30px 0px rgb(113 122 131 / 11%)",
        },
      }}
    >
      {SidebarContent}
    </Drawer>
  );
};
