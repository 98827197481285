import { Box, Typography } from "@mui/material";
import React from "react";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";

type Props = {
  isDragOver: boolean;
};

export const DropBox: React.FC<Props> = ({ isDragOver }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100px",
        border: "dotted",
        borderWidth: 1,
        borderColor: theme.lightblue.main,
        borderRadius: "16px",
        boxShadow: `0px 7px 30px ${theme.cardShadow}`,
        opacity: isDragOver ? 0.4 : 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: isDragOver ? theme.lightblue.main : theme.white,
      }}
    >
      <Typography
        sx={{
          color: isDragOver ? theme.white : theme.gray800,
          fontSize: "16px",
          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        }}
      >
        {textJa.common.dragArea(textJa.common.selectFile)}
      </Typography>
    </Box>
  );
};
