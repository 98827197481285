import { Box } from "@mui/material";
import { FC } from "react";
import { textJa } from "../../locales/textJa";
import { TitleTypo } from "../common/styles/title";
import { RowNumSelect } from "./RowNumSelect";
import { Search } from "./Search";

type Props = {
  setSearchKeyword: React.Dispatch<React.SetStateAction<string>>;
  rowNum: Number;
  setRowNum: React.Dispatch<React.SetStateAction<Number>>;
};

export const SearchArea: FC<Props> = ({
  setSearchKeyword,
  rowNum,
  setRowNum,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        paddingBottom: "24px",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <TitleTypo>{textJa.home.title}</TitleTypo>
      <Search setSearchKeyword={setSearchKeyword} />
      <RowNumSelect rowNum={rowNum} setRowNum={setRowNum} />
    </Box>
  );
};
