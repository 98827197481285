import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";
import { theme } from "../../libs/theme";

export const AnalysisCreateIcon = () => {
  const navigate = useNavigate();
  const onClickCreate = () => {
    navigate("recording");
  };

  return (
    <Box sx={{ position: "fixed", right: "56px", bottom: "48px" }}>
      <IconButton
        size="large"
        color="inherit"
        aria-label="menu"
        onClick={onClickCreate}
        sx={{
          mt: "24px",
        }}
      >
        <Box
          sx={{
            borderRadius: "50%",
            backgroundColor: theme.defaultColor,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "40px",
            height: "40px",
          }}
        >
          <FeatherIcon
            icon="plus"
            color={theme.white}
            width="80%"
            height="80%"
          />
        </Box>
      </IconButton>
    </Box>
  );
};
