export const formatStringTime = (time: string | undefined | null) => {
  if (!time) return;
  const numberTime = Number(time) | 0;
  const formatString = `${getdoubleDigestNumer(
    (numberTime / 3600) | 0
  )}:${getdoubleDigestNumer(
    ((numberTime % 3600) / 60) | 0
  )}:${getdoubleDigestNumer(numberTime % 60)}`;

  return formatString;
};

export const formatNumberTime = (time: number | undefined | null) => {
  if (time === undefined || time === null) return;
  const numberTime = time | 0;
  const formatString = `${getdoubleDigestNumer(
    (numberTime / 3600) | 0
  )}:${getdoubleDigestNumer(
    ((numberTime % 3600) / 60) | 0
  )}:${getdoubleDigestNumer(numberTime % 60)}`;

  return formatString;
};

const getdoubleDigestNumer = (time: number) => {
  return ("0" + time).slice(-2);
};
