import { Box, Modal, styled, Typography } from "@mui/material";
import React from "react";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";
import { CancelButton, SendButton } from "./styles/button";

const StyledModal = styled(Modal)(() => ({
  "> .MuiBox-root": {
    border: "none",
    borderRadius: "16px",
    boxShadow: "0 0 8px gray",
    outline: "none",
    zIndex: "999",
  },
}));

type Props = {
  modalOpen: boolean;
  onCloseModal: () => void;
  text: string;
  onYesClick: () => void;
  onNoClick: () => void;
  failMessage?: string | null;
  showFailMessage?: boolean;
};

export const BaseModal: React.FC<Props> = ({
  modalOpen,
  onCloseModal,
  text,
  onYesClick,
  onNoClick,
  failMessage = null,
  showFailMessage = false,
}) => {
  return (
    <StyledModal
      open={modalOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        maxWidth={700}
        minWidth={350}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: "center" }}
        >
          {text}
        </Typography>
        {showFailMessage && (
          <Typography
            component="p"
            sx={{
              textAlign: "center",
              color: theme.red,
            }}
          >
            {failMessage}
          </Typography>
        )}
        <Box
          id="modal-modal-description"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "40px",
            justifyContent: "center",
            mt: "16px",
          }}
        >
          <CancelButton onClick={onNoClick}>{textJa.no}</CancelButton>
          <SendButton onClick={onYesClick}>{textJa.yes}</SendButton>
        </Box>
      </Box>
    </StyledModal>
  );
};
