import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";

type Props = {
  rowNum: Number;
  setRowNum: React.Dispatch<React.SetStateAction<Number>>;
};

export const RowNumSelect: React.FC<Props> = ({ rowNum, setRowNum }) => {
  const pullDownItemList = textJa.home.rowNumList;
  const handleOnChange = (e: any) => {
    setRowNum(e.target.value);
  };
  return (
    <FormControl size="small">
      <InputLabel
        sx={{
          width: "100%",
          color: theme.gray800,
        }}
      >
        {textJa.home.rowNumTitle}
      </InputLabel>
      {pullDownItemList && (
        <Select
          label={textJa.home.rowNumTitle} //表示したいタイトルの長さ分、枠確保
          value={rowNum}
          onChange={handleOnChange}
          sx={{
            backgroundColor: theme.white,
            color: theme.gray800,
          }}
        >
          {pullDownItemList.map((item, index) => (
            <MenuItem value={item} key={index}>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {`${item}`}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};
