import { IconButton, styled, TableCell, Typography } from "@mui/material";
import axios, { Axios } from "axios";
import encoding from "encoding-japanese";
import ExcelJS from "exceljs";
import FeatherIcon from "feather-icons-react";
import { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatNumberTime, formatStringTime } from "../../libs/formatTime";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";
import { LoadingContext, UserContext } from "../../Main";
import { historyDataType } from "../../types/type";
import { BaseModal } from "../common/BaseModal";
import { NotOpenDetailModal } from "./NotOpenDetailModal";
import { StatusTag } from "./StatusTag";
type Props = {
  item: historyDataType;
  getDataList: () => Promise<void>;
};

const StyledTypography = styled(Typography)(() => ({
  color: theme.gray800,
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "21px",
}));

export const Row: FC<Props> = ({ item, getDataList }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [notDetailModalOpen, setNotDetailModalOpen] = useState<boolean>(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const { setIsLoading } = useContext(LoadingContext);

  const onClickEdit = (link: string) => {
    if (item.analysis_status === textJa.analysisStatus.succeeded) {
      const editlink = "edit/" + link;
      navigate(editlink);
    } else {
      setNotDetailModalOpen(true);
    }
  };

  const user = useContext(UserContext);
  const InstanceAxios = (): Axios => {
    // Header Authorization
    const accessToken = user?.token;
    if (accessToken === "") {
      console.log("home Row: accessToken is null.");
    }
    let axiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return axiosInstance;
  };

  const onClickDelete = async () => {
    handleClose();
    setIsLoading(true);
    let axiosInstance = InstanceAxios();
    await axiosInstance
      .post(`${process.env.REACT_APP_ANALYSIS_RESULT_DELETE_API}&id=${item.id}`)
      .then(() => {
        setTimeout(() => {
          getDataList();
          setIsLoading(false);
        }, 2000);
      })
      .catch((err: any) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const onClickDownload = async () => {
    let axiosInstance = InstanceAxios();
    const tmpDownloadItem = await axiosInstance.get(
      `${process.env.REACT_APP_DOWNLOAD_API}&type=json&id=${item.id}`
    );
    const jsonItem = JSON.parse(tmpDownloadItem.data);
    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet("sheet1");
    const worksheet = workbook.getWorksheet("sheet1");
    worksheet.columns = [
      { header: "要点", key: "mainPoint" },
      { header: "話者", key: "speaker" },
      { header: "開始時間", key: "start" },
      { header: "終了時間", key: "end" },
      { header: "文字起こし", key: "analysis" },
    ];
    let row = [];
    if ("startTime" in jsonItem.result[0]) {
      for (let i = 0; i < jsonItem.result.length; i++) {
        row.push({
          mainPoint: jsonItem.result[i].mainPoint,
          speaker: jsonItem.result[i].speaker,
          analysis: jsonItem.result[i].text,
          start: formatNumberTime(jsonItem.result[i].startTime),
          end: formatNumberTime(jsonItem.result[i].endTime),
        });
      }
    } else {
      for (let i = 0; i < jsonItem.result.length; i++) {
        row.push({
          mainPoint: jsonItem.result[i].mainPoint,
          speaker: jsonItem.result[i].speaker,
          analysis: jsonItem.result[i].text,
          start: formatNumberTime(jsonItem.result[i].offset),
          end: formatNumberTime(
            jsonItem.result[i].offset + jsonItem.result[i].duration
          ),
        });
      }
    }
    worksheet.addRows(row);
    const u8abase = await workbook.csv.writeBuffer();

    const uint8Array = new Uint8Array(
      encoding.convert(u8abase as Int32Array, {
        from: "UTF8",
        to: "SJIS",
      })
    );
    var blob = new Blob([uint8Array], { type: "application/octet-binary" }); //配列に上記の文字列(str)を設定
    var link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${jsonItem.audioFileName}.csv`;
    link.click();
  };

  return (
    <>
      <TableCell onClick={() => onClickEdit(item.id!)}>
        <StyledTypography
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "48px",
            overflow: "hidden",
          }}
        >
          {item.id}
        </StyledTypography>
      </TableCell>
      <TableCell onClick={() => onClickEdit(item.id!)}>
        <StyledTypography
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "250px",
            overflow: "hidden",
          }}
        >
          {item.title}
        </StyledTypography>
      </TableCell>
      <TableCell onClick={() => onClickEdit(item.id!)}>
        <StyledTypography
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "250px",
            overflow: "hidden",
          }}
        >
          {item.site_name}
        </StyledTypography>
      </TableCell>
      <TableCell onClick={() => onClickEdit(item.id!)}>
        <StyledTypography
          sx={{
            maxWidth: "150px",
          }}
        >
          {item.created_date}
        </StyledTypography>
      </TableCell>
      <TableCell onClick={() => onClickEdit(item.id!)}>
        <StyledTypography
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "100px",
            overflow: "hidden",
          }}
        >
          {formatStringTime(item.mtg_time)}
        </StyledTypography>
      </TableCell>

      <TableCell onClick={() => onClickEdit(item.id!)}>
        <StatusTag size="0" messagetype={item.analysis_status} />
      </TableCell>
      <TableCell>
        <IconButton
          size="large"
          aria-label="menu"
          onClick={handleOpen}
          disabled={
            item.analysis_status !== textJa.analysisStatus.succeeded &&
            item.analysis_status !== textJa.analysisStatus.failed
          }
          sx={{
            display: "flex",
          }}
        >
          <FeatherIcon icon="trash-2" />
        </IconButton>
      </TableCell>
      <TableCell>
        <IconButton
          size="large"
          aria-label="menu"
          onClick={onClickDownload}
          disabled={item.analysis_status !== textJa.analysisStatus.succeeded}
          sx={{
            display: "flex",
          }}
        >
          <FeatherIcon icon="download" />
        </IconButton>
      </TableCell>
      <BaseModal
        modalOpen={open}
        onCloseModal={handleClose}
        text={textJa.dialog(textJa.home.delete)}
        onNoClick={handleClose}
        onYesClick={onClickDelete}
      />
      <NotOpenDetailModal
        notDetailModalOpen={notDetailModalOpen}
        setNotDetailModalOpen={setNotDetailModalOpen}
        word={textJa.home.notOpen}
      />
    </>
  );
};
