import { Box } from "@mui/material";
import { BaseCard } from "../components/common/BaseCard";
import { TitleTypo } from "../components/common/styles/title";
import { ContactPerformance } from "../components/contact/ContactPerformance";
import { textJa } from "../locales/textJa";

export const Contact = () => {
  return (
    <Box>
      <TitleTypo>{textJa.contact.title}</TitleTypo>
      <Box sx={{ mx: "8%" }}>
        <BaseCard>
          <ContactPerformance />
        </BaseCard>
      </Box>
    </Box>
  );
};
