import { Button, styled } from "@mui/material";
import { theme } from "../../../libs/theme";

export const SendButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: theme.subColor,
  borderRadius: 50,
  padding: "8px 24px",
  boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  "&:hover": {
    backgroundColor: theme.subColor,
    opacity: 0.8,
  },
  "&:disabled": {
    backgroundColor: theme.gray500,
    color: theme.white,
  },
}));

export const CancelButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: theme.gray500,
  borderRadius: 50,
  padding: "8px 24px",
  boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  "&:hover": {
    backgroundColor: theme.gray500,
    opacity: 0.8,
  },
}));

export const LogoutButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: theme.defaultColor,
  borderRadius: 50,
  padding: "8px 24px",
  boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  "&:hover": {
    backgroundColor: theme.defaultColor,
    opacity: 0.8,
  },
}));

export const LoginButtonStyle = styled(Button)(() => ({
  color: "white",
  backgroundColor: theme.loginpink,
  borderRadius: 50,
  padding: "8px 24px",
  boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  "&:hover": {
    backgroundColor: theme.loginpink,
    opacity: 0.8,
  },
}));
