import {
  Box,
  Button,
  Card,
  CardContent,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";

const HogeCard = styled(CardContent)`
  &:last-child {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

export const ReloadAnnounce: React.FC = () => {
  return (
    <Card
      sx={{
        borderRadius: "12px",
        width: "650px",
        boxShadow: `0px 7px 30px ${theme.cardShadow}`,
        padding: "0",
        position: "fixed",
        top: "100%",
        left: "50%",
        transform: "translate(-50%, -100%)",
      }}
    >
      <HogeCard>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>{textJa.home.reloadDetail}</Typography>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              window.location.reload();
            }}
            sx={{ margin: "0" }}
          >
            {textJa.home.reload}
          </Button>
        </Box>
      </HogeCard>
    </Card>
  );
};
