import { Box, Link, styled, Typography } from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";

const StyledTypography = styled(Typography)(() => ({
  color: theme.gray800,
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "21px",
}));

export const ContactPerformance = () => {
  return (
    <Box>
      <StyledTypography>{textJa.contact.text1}</StyledTypography>
      <StyledTypography>{textJa.contact.text2}</StyledTypography>
      <StyledTypography>{textJa.contact.text3}</StyledTypography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "5%",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "48px",
            width: "48px",
            minWidth: "40px",
            backgroundColor: theme.defaultColor,
            borderRadius: "50%",
            marginRight: "24px",
          }}
        >
          <FeatherIcon
            icon="mail"
            size="24px"
            style={{
              color: theme.white,
              backgroundColor: theme.defaultColor,
            }}
          />
        </Box>
        <Link href={`mailto:${textJa.contact.address}`}>
          {textJa.contact.address}
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "22px",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "48px",
            width: "48px",
            minWidth: "40px",
            backgroundColor: theme.subColor,
            borderRadius: "50%",
            marginRight: "24px",
          }}
        >
          <FeatherIcon
            icon="phone-call"
            size="24px"
            style={{
              color: theme.white,
              backgroundColor: theme.subColor,
            }}
          />
        </Box>
        <Box>
          <StyledTypography>{textJa.contact.text4}</StyledTypography>
          <StyledTypography>{textJa.contact.text5}</StyledTypography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "22px",
        }}
      ></Box>
    </Box>
  );
};
