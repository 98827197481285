import { Box, Pagination, PaginationItem } from "@mui/material";
import { FC } from "react";

type PaginationType = {
  allPage: number;
  page: number;
  size: "small" | "large" | "medium";
  color: "primary" | "secondary" | "standard" | undefined;
  onChange: (newPageNum: number) => void;
};

export const PaginatedItems: FC<PaginationType> = ({
  allPage,
  page,
  size,
  color,
  onChange,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Pagination
        count={allPage}
        onChange={(e, newPageNum) => onChange(newPageNum)}
        page={page}
        size={size}
        color={color}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            sx={{
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
        )}
      />
    </Box>
  );
};
