import { Box } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { BaseCard } from "../components/common/BaseCard";
import { TitleTypo } from "../components/common/styles/title";
import { RegisterIcon } from "../components/registerPerson/RegisterIcon";
import { RegisterPersonPerformance } from "../components/registerPerson/RegisterPersonPerformance";
import { textJa } from "../locales/textJa";
import { LoadingContext, UserContext } from "../Main";
import { registerPeopleType } from "../types/type";

export const RegisterPerson = () => {
  const user = useContext(UserContext);

  // Header Authorization
  const accessToken = user?.token;
  if (accessToken === "") {
    console.log("home Row: accessToken is null.");
  }
  let axiosInstance = axios.create({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const [speaker, setSpeaker] = useState<registerPeopleType[]>([]);
  const { setIsLoading } = useContext(LoadingContext);
  const [loadCompleted, setLoadCompleted] = useState<boolean>(false);

  const getSpeaker = async () => {
    setIsLoading(true);
    const tmpSpeaker = await axiosInstance.get(
      `${process.env.REACT_APP_LIST_API}&username=${user?.email}`
    );
    setIsLoading(false);
    setSpeaker(tmpSpeaker.data);
  };

  useEffect(() => {
    if (accessToken && !loadCompleted) {
      getSpeaker();
      setLoadCompleted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  return (
    <Box>
      <TitleTypo>{textJa.registerPerson.title}</TitleTypo>
      <BaseCard>
        <RegisterPersonPerformance items={speaker} getSpeaker={getSpeaker} />
      </BaseCard>
      <RegisterIcon />
    </Box>
  );
};
