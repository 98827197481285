import { Box } from "@mui/material";
import "./styles/loading.css";

export const Loading = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(0, 0, 0, 0.5)",
        zIndex: 999999,
      }}
    >
      <div className="loader5"></div>
    </Box>
  );
};
