import { createContext, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Loading } from "./components/common/Loading";
import { DefaultLayout } from "./components/defaultLayout/DefaultLayout";
import { useUser } from "./hooks/useUser";
import { AnalysisEdit } from "./pages/AnalysisEdit";
import { Contact } from "./pages/Contact";
import { Home } from "./pages/Home";
import { NotFound } from "./pages/NotFound";
import { Realtime } from "./pages/RealTime";
import { RecordingFileAnalysis } from "./pages/RecordingFileAnalysis";
import { RegisterCreate } from "./pages/RegisterCreate";
import { RegisterPerson } from "./pages/RegisterPerson";
import { User } from "./types/type";

type ContextType = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const UserContext = createContext<User>({} as User);
export const LoadingContext = createContext<ContextType>({} as ContextType);

export const Main = () => {
  const user = useUser();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <UserContext.Provider value={user as User}>
      <LoadingContext.Provider value={{ setIsLoading }}>
        <DefaultLayout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/edit/:id" element={<AnalysisEdit />} />
            <Route path="/recording" element={<RecordingFileAnalysis />} />
            <Route path="/register" element={<RegisterPerson />} />
            <Route path="/register/create" element={<RegisterCreate />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="realtime" element={<Realtime />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </DefaultLayout>
        {isLoading && <Loading />}
      </LoadingContext.Provider>
    </UserContext.Provider>
  );
};
